import React from 'react';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import HeaderButton from "../../components/utils/button/HeaderButton";
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikTextInput, FormikDateInput } from '../../components/utils/inputs/FormikInputs';
// styles
import { FiltrosStyles } from '../Filtros.styles';
// assets
import borrar from "../../assets/icons/borrar.svg";
import reload from "../../assets/icons/actualizar.svg";
// form
import { useFormik } from 'formik';
import * as yup from "yup";

const validationSchema = yup.object({
    apellido: yup.string().min(3, 'Debe tener minimo 3 letras'),
    dni: yup.string(),
    email: yup.string().min(3, "Debe tener minimo 3 letras"),
    telefono: yup.string(),
    cvu: yup.number('Debe ser un numero'),
    desde: yup.date().max(yup.ref('hasta'), 'No puede ser posterior a la segunda fecha'),
    hasta: yup.date()
});

export default function Filtros(props) {
    const { filtros, setFiltros, updateList, cargando } = props;

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { apellido: { label: 'Apellido' }, documento: { label: 'Documento' }, email: { label: 'Email' }, telefono: { label: 'Telefono' }, cvu: { label: 'CVU' }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } };
    const classes = FiltrosStyles();

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="apellido"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.apellido !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('apellido', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="documento"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.documento !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('documento', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="email"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.email !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('email', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="telefono"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.telefono !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('telefono', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="cvu"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.cvu !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('cvu', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Buscar" />
                </div>
                <Buttons>
                    <HeaderButton
                        text="Actualizar pagina"
                        icon={reload}
                        updateList={updateList}
                    />
                </Buttons>
            </div>
        </form>
    )
};