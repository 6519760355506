// services
import { aseguradoraIns } from '../../../../services/hooli-services/Aseguradoras/AseguradoraIns';
import { aseguradoraDel } from '../../../../services/hooli-services/Aseguradoras/AseguradoraDel';
import { aseguradoraUpd } from '../../../../services/hooli-services/Aseguradoras/AseguradoraUpd';

export async function postAnAseguradora(credentials, newAseguradora, newImage, setInRequest) {
    if (Object.keys(newAseguradora).length > 0) {
        const payload = {
            ...newAseguradora,
            photo: newImage,
            ...credentials
        };
        setInRequest(true);
        const response = await aseguradoraIns(payload);
        return response
    }
};

export async function deleteAseguradora(credentials, row, setInRequest) {
    const { id } = row;

    const payload = { ...credentials, id }
    setInRequest(true);
    const response = await aseguradoraDel(payload)
    return response
};

export async function updateAseguradora(credentials, newAseguradora, row, newImage, src, setInRequest) {
    if (Object.keys(newAseguradora).length > 0) {
        const { id, version } = row;
        const { insurer, codigo, hooli } = newAseguradora

        const imagePathASEGURA = newImage ? newImage : src

        const payload = {
            id,
            aseguradoraNombre: insurer,
            aseguradoraCod: codigo,
            aseguradoraHooli: hooli,
            version,
            imagePathASEGURA,
            ...credentials
        };
        setInRequest(true);
        const response = await aseguradoraUpd(payload);
        return response
    }
};