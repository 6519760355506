import React, { useState } from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
// assets
import IconTables from "../../assets/icons/icon-tables.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
// styles
import { ListDrawerStyles } from './ListDrawer.styles';
// const
import { Routes, OnBoarding, Tablas } from "../../consts/Routes-App";
// Recoil
import { useSetRecoilState } from "recoil";
import { routeInfo } from "../../recoilState/GlobalState";

export default function ListDrawer({ setOpen }) {
    const [onboardingOpen, setOnboardingOpen] = useState(false);
    const [tablasOpen, setTablasOpen] = useState(false);

    const classes = ListDrawerStyles();
    const setRoute = useSetRecoilState(routeInfo);

    const handleClick = (route) => {
        setRoute(route);
        setOpen(false);
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {/* Routes */}
            {Object.values(Routes).map(route => {
                return <Link to={route.route} style={{ textDecoration: "none" }} key={route.route}>
                    <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleClick(route)}
                    >
                        <span className={classes.textColor}>{route.wordKey}</span>
                    </ListItem>
                </Link>
            })}
            {/* Onboarding */}
            <ListItem button className={classes.itemList} onClick={() => setOnboardingOpen(!onboardingOpen)}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Onboarding</span>
                <img src={ArrowDown} className="icon-expand" alt="arrow-down" />
            </ListItem>
            <Collapse in={onboardingOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.values(OnBoarding).map(route => {
                        if (route.route !== '/') {
                            return <Link to={route.route} style={{ textDecoration: "none" }} key={route.route}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleClick(route)}
                                >
                                    <span className={classes.textColor}>{route.wordKey}</span>
                                </ListItem>
                            </Link>
                        }
                        return undefined
                    })}
                </List>
            </Collapse>
            {/* Tablas */}
            <ListItem button className={classes.itemList} onClick={() => setTablasOpen(!tablasOpen)}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Tablas</span>
                <img src={ArrowDown} className="icon-expand" alt="arrow-down" />
            </ListItem>
            <Collapse in={tablasOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.values(Tablas).map(route => {
                        if (route.route !== '/') {
                            return <Link to={route.route} style={{ textDecoration: "none" }} key={route.route}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleClick(route)}
                                >
                                    <span className={classes.textColor}>{route.wordKey}</span>
                                </ListItem>
                            </Link>
                        }
                        return undefined
                    })}
                </List>
            </Collapse>
        </List>
    );
}
