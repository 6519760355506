import React, { useState, useEffect } from "react";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// consts
import { aseguradoras as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoAseguradoras as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { aseguradoraGet } from '../../services/hooli-services/Aseguradoras/AseguradoraGet';
import { deviceGet } from "../../services/hooli-services/Devices/DevicesGet";
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function Aseguradora() {
    const [aseguradoras, setAseguradoras] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);

    const [{ status }, setRow] = useRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData)

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, aseguradoraGet, setAseguradoras);
        await retrieveData(credentials, undefined, deviceGet, () => {})
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList();
    }, [credentials]);

    useEffect(() => {
        aseguradoras.map((item) => {
            const {
                aseguradoraId,
                aseguradoraVersion,
                aseguradoraNombre,
                aseguradoraHooli,
                aseguradoraCod,
                imagePathASEGURA
            } = item

            const aseguradora = {
                id: aseguradoraId,
                version: aseguradoraVersion,
                hooli: aseguradoraHooli,
                [titles[0]]: aseguradoraNombre,
                [titles[1]]: aseguradoraCod,
                [titles[2]]: aseguradoraHooli === 'Y' ? 'Si' : 'No',
                [titles[3]]: imagePathASEGURA ? 'Si' : 'No',
                imagePathASEGURA
            }

            setRows(rows => [...rows, aseguradora])
            return item
        })
    }, [aseguradoras]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons texto="aseguradora" handleClick={handleClick} updateList={updateList}/>
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};