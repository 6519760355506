import React from 'react';
import Mapa from '../Mapa';

function DomicilioPersonal() {
    return (
        <div className="datos-personales">
            <div className="mapa">
                <Mapa />
            </div>
            <div className="datos-personales-column">
                <p className="dato-personal-label">Calle</p>
                <p className="dato-personal">Roundeau</p>
                <p className="dato-personal-label">Numero</p>
                <p className="dato-personal">150</p>
                <p className="dato-personal-label">Piso</p>
                <p className="dato-personal">5</p>
                <p className="dato-personal-label">Departamento</p>
                <p className="dato-personal">E</p>
                <p className="dato-personal-label">Codigo Postal</p>
                <p className="dato-personal">5000</p>
            </div>
            <div className="datos-personales-column">
                <p className="dato-personal-label">Provincia</p>
                <p className="dato-personal">Cordoba</p>
                <p className="dato-personal-label">Localidad</p>
                <p className="dato-personal">Cordoba</p>
                <p className="dato-personal-label">Longitud</p>
                <p className="dato-personal">xxxxx</p>
                <p className="dato-personal-label">Latitud</p>
                <p className="dato-personal">xxxxx</p>
            </div>
        </div>
    )
};

export default DomicilioPersonal