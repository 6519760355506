// services
import { formatosXTipoDeImagenIns } from '../../../../services/hooli-services/FormatosXTipoDeImagen/FormatosXTipoDeImagenIns';
import { formatosXTipoDeImagenDel } from '../../../../services/hooli-services/FormatosXTipoDeImagen/FormatosXTipoDeImagenDel';
import { formatosXTipoDeImagenUpd } from '../../../../services/hooli-services/FormatosXTipoDeImagen/FormatosXTipoDeImagenUpd';

export async function postFormato(credentials, newFormato, setInRequest) {
    if (Object.keys(newFormato).length > 0) {
        const payload = { ...newFormato, ...credentials };

        setInRequest(true);
        const response = await formatosXTipoDeImagenIns(payload);
        return response
    }
};

export async function deleteFormato(credentials, row, setInRequest) {
    const { id } = row
    const payload = { id, ...credentials }

    setInRequest(true);
    const response = await formatosXTipoDeImagenDel(payload);
    return response
};

export async function updateFormato(credentials, newFormato, row, setInRequest) {
    if (Object.keys(newFormato).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newFormato,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await formatosXTipoDeImagenUpd(payload)
        return response
    }
};