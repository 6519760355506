import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from './components/header/Header';
// Dashboard
import Dashboard from "./pages/dashboard/Dashboard";
// OnBoarding
import EndUser from './pages/onBoardingEndUser/EndUser';
import Merchant from './pages/onBoardingMerchant/Merchant';
// Tablas
import Aseguradoras from "./pages/aseguradora/Aseguradoras"
import Localidades from "./pages/localidades/Localidades";
import CondicionesAFIP from './pages/condicionesAFIP/CondicionesAFIP';
import TiposDeDomicilio from './pages/tiposDeDomicilio/tiposDeDomicilio';
import TiposDeImagen from './pages/tiposDeImagen/tiposDeImagen';
import FormatosDeImagen from './pages/formatosDeImagen/FormatosDeImagen';
import FormatosXTipoDeImagen from './pages/formatosXTipoDeImagen/FormatosXTipoDeImagen';
import Monedas from './pages/monedas/Monedas';
import CotizacionesDeMonedas from './pages/cotizacionesDeMonedas/CotizacionesDeMonedas';
import Feriados from './pages/feriados/Feriados';
import SerieDeFeriados from './pages/serieDeFeriados/SerieDeFeriados';
import Registros from './pages/registros/Registros';
import TiposDeRegistro from "./pages/tiposDeRegistro/TiposDeRegistro";
import EstadosDeRegistro from "./pages/estadosDeRegistro/EstadosDeRegistro";
import EventosDeSesion from "./pages/eventosDeSesion/EventosDeSesion";
import TiposDeDevice from "./pages/tiposDeDevice/TiposDeDevice";
import ZonasDeNoEnroll from './pages/zonasDeNoEnroll/ZonasDeNoEnroll';
import Merchants from './pages/merchants/Merchants';
import RubrosDeMerchant from "./pages/rubrosDeMerchant/RubrosDeMerchant";
import Cargos from './pages/cargos/Cargos';
import UsuarioActivo from "./pages/usuarioActivo/UsuarioActivo";
import Usuarios from "./pages/usuarios/Usuarios";
import BusquedaUsuarios from "./pages/busquedaUsuarios/BusquedaUsuarios";
import PreonboardingManual from "./pages/preonboardingManual/PreonboardingManual";
// consts
import { OnBoarding, Routes, Tablas } from "./consts/Routes-App";
//Credentials services
import { usuarioMinimosGet } from './services/hooli-services/UsuarioMinimosGet';
// recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userInfo, userCredentials, routeInfo } from './recoilState/GlobalState';
import { checkAuth } from "./services/hooli-services/checkAuth";
import { getUserCredentials } from "./services/hooli-services/GetUserCredentials";
import 'dotenv/config';
import SnackBar from "./components/utils/snackbars/Snackbar";
import UsuariosNoUsuarios from "./pages/usuariosNoUsuarios/UsuariosNoUsuarios";
import UsuarioNoUsuarioManualPreOnBoarding from "./pages/usuarioNoUsuarioDetail/UsuarioNoUsuarioManualPreOnBoarding";

export default function App() {
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const setInfo = useSetRecoilState(userInfo);
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        (async () => {
            const data = await getUserCredentials();
            // const data = checkAuth();
            setCredentials(data);
            if (window.location.search) window.history.replaceState({}, document.location, '/');

            const routes = { ...Routes, ...OnBoarding, ...Tablas }
            if (routes[window.location.pathname.split('/')[1]]) setRoute(routes[window.location.pathname.split('/')[1]])
        })()
    }, []);

    useEffect(() => {
        async function retrieveUsuarioMinimo() {
            const data = await usuarioMinimosGet(credentials);
            if (data) setInfo(data)
        };
        if (Object.keys(credentials).length > 0) retrieveUsuarioMinimo()
    }, [credentials])

    return (
        <Router>
            <Header />
            <SnackBar />
            <Switch>
                {/* DASHBOARD */}
                <Route path={Routes.dashboard.route} exact render={() => (<Dashboard />)} />
                {/* PREONBOARDING MANUAL */}
                <Route path={Routes.preonboardingManual.route} exact render={() => <PreonboardingManual />} />
                {/* USUARIOS ACTIVOS */}
                <Route path={Routes.usuarios.route} exact render={() => (<Usuarios />)} />
                <Route path={Routes.usuariosNoUsuarios.route} exact render={() => (<UsuariosNoUsuarios />)} />
                {/* Busqueda usuarios */}
                <Route path={Routes.busquedaDeUsuario.route} exact render={() => <BusquedaUsuarios />} />
                <Route path={`${Routes.usuarios.route}/:id`} exact render={() => (<UsuarioActivo />)} />
                <Route path={`${Routes.usuariosNoUsuarios.route}/:id`} exact render={() => (<UsuarioNoUsuarioManualPreOnBoarding />)} />
                {/* ONBOARDING */}
                <Route path={OnBoarding.onboardingEnduser.route} exact render={() => <EndUser />} />
                <Route path={OnBoarding.onboardingMerchant.route} exact render={() => <Merchant />} />
                {/* TABLAS */}
                <Route path={Tablas.aseguradoras.route} exact render={() => (<Aseguradoras />)} />
                <Route path={Tablas.localidades.route} exact render={() => (<Localidades />)} />
                <Route path={Tablas.condicionesAFIP.route} exact render={() => (<CondicionesAFIP />)} />
                <Route path={Tablas.tiposDeDomicilio.route} exact render={() => (<TiposDeDomicilio />)} />
                <Route path={Tablas.tiposDeImagen.route} exact render={() => (<TiposDeImagen />)} />
                <Route path={Tablas.formatosDeImagen.route} exact render={() => (<FormatosDeImagen />)} />
                <Route path={Tablas.formatosXTipoDeImagen.route} exact render={() => (<FormatosXTipoDeImagen />)} />
                <Route path={Tablas.monedas.route} exact render={() => (<Monedas />)} />
                <Route path={Tablas.cotizacionesDeMonedas.route} exact render={() => (<CotizacionesDeMonedas />)} />
                <Route path={Tablas.feriados.route} exact render={() => (<Feriados />)} />
                <Route path={Tablas.seriesDeFeriados.route} exact render={() => <SerieDeFeriados />} />
                <Route path={Tablas.registros.route} exact render={() => (<Registros />)} />
                <Route path={Tablas.tiposDeRegistro.route} exact render={() => (<TiposDeRegistro />)} />
                <Route path={Tablas.estadosDeRegistro.route} exact render={() => (<EstadosDeRegistro />)} />
                <Route path={Tablas.eventosDeSesion.route} exact render={() => <EventosDeSesion />} />
                {/* <Route path={Tablas.devices.route} exact render={() => <Devices route={Tablas.devices} />} /> */}
                <Route path={Tablas.tiposDeDevice.route} exact render={() => <TiposDeDevice />} />
                <Route path={Tablas.zonasDeNoEnroll.route} exact render={() => <ZonasDeNoEnroll />} />
                <Route path={Tablas.merchants.route} exact render={() => (<Merchants />)} />
                <Route path={Tablas.rubrosDeMerchant.route} exact render={() => (<RubrosDeMerchant />)} />
                <Route path={Tablas.cargos.route} exact render={() => (<Cargos />)} />
            </Switch>
        </Router>
    );
};