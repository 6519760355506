import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().max(30, "Debe tener maximo 30 letras").required("Ingrese un nombre"),
    cuit: yup.string().matches(/\d{2}[-]\d{7,8}[-]\d{1}/, "Ingrese un cuit valido").required("Ingrese un cuit"),
    plan: yup.string().required("Ingrese un plan"),
    rubro: yup.string().required("Ingrese un rubro"),
    acreditacion: yup.string().required("Ingrese una acreditacion")
});

export const formikValidation = (setNewMerchant, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        cuit: row && row[titles[1]] ? row[titles[1]] : "",
        plan: row && row[titles[2]] ? row[titles[2]] : "",
        rubro: row && row[titles[3]] ? row[titles[3]] : "",
        acreditacion: row && row[titles[4]] ? row[titles[4]] : "",
    },
    validationSchema,
    onSubmit: (values) => setNewMerchant(values)
});