import axios from 'axios';
import { serviceURL } from '../../consts/ServiceURL';

export const getUserCredentials = async () => {
    let userCredentials
    if(localStorage.getItem('hooliToken') && localStorage.getItem('sessionId')){
        return {
            hooliToken: localStorage.getItem('hooliToken'),
            sessionId: localStorage.getItem('sessionId')
        }
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {
            "service": "LogInDNI",
            "params": {
                "usuarioNroDeDocumento": 42683884,
                "tipoDeDocumentoCod": "DNI",
                "usuarioFechaDeNacimiento": "00-08-11",
                "usuarioSexo": "M",
                "deviceId": "396eeccf5a3c341c",
                "registroXUsuarioLatitud": -33.123456,
                "registroXUsuarioLongitud": -55.123456,
                "appVersion": "0.23",
                "osVersion": "pirulo"
            }
        }
    };
    try {
        userCredentials = await axios.post(serviceURL, requestOptions.body, requestOptions.headers)
    } catch (err) {
        console.log(`Error: ${err}`)
    }
    if (!userCredentials.data || userCredentials.data === null) {
        console.log(`Fetch failed`)
    }

    localStorage.setItem('hooliToken', userCredentials.data.result[0].hooliToken);
    localStorage.setItem('sessionId', userCredentials.data.result[0].sessionId);
    
    return userCredentials.data.result[0]
}