import React from 'react';
import Mapa from '../Mapa';

function Datos() {
    return (
        <div className="datos-personales">
            <div className="mapa">
                <Mapa />
            </div>
            <div className="datos-personales-column">
                <p className="dato-personal-label">Fecha y hora</p>
                <p className="dato-personal">22/09/2020 17:45</p>
                <p className="dato-personal-label">Coordenadas</p>
                <p className="dato-personal">31°231°22’50.3”S 64°14’21.1”W</p>
                <p className="dato-personal-label">Marca del dispositivo</p>
                <p className="dato-personal">Apple</p>
                <p className="dato-personal-label">Modelo</p>
                <p className="dato-personal">Iphone SE</p>
            </div>
            <div className="datos-personales-column">
                <p className="dato-personal-label">Sistema Operativo</p>
                <p className="dato-personal">OS 14.3.1</p>
                <p className="dato-personal-label">Dev Name</p>
                <p className="dato-personal">Iphone de Lorena</p>
                <p className="dato-personal-label">SDK</p>
                <p className="dato-personal">4.1</p>
                <p className="dato-personal-label">FMR</p>
                <p className="dato-personal">00138021396F19940922</p>
            </div>
            <div className="datos-personales-column">
                <p className="dato-personal-label">ID de registro</p>
                <p className="dato-personal">00138021396F19940922</p>
                <p className="dato-personal-label">App version</p>
                <p className="dato-personal">2.4.5</p>
                <p className="dato-personal" style={{ color: '#9373B1' }}>Usuario registrado con dispositivo personal</p>
            </div>
        </div>
    )
}

export default Datos
