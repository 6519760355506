import { makeStyles } from '@material-ui/core/styles'

export const SubmitButtonStyles = makeStyles(() => ({
    root: {
        width: "80px",
        letterSpacing: "0rem",
        height: "2rem",
        color: "#ffffff",
        opacity: 1,
        background: "linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)",
        borderRadius: 21,
        marginRight: "2rem",
        fontWeight: 300,
        fontFamily: '"Roboto", sans-serif',
        textTransform: 'capitalize',
        "&:hover": {
            background: "linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)",
        },
        "&:disabled": {
            background: "#A7A7A7",
        }
    }
}));