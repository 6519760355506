import axiosPost from '../axiosPost';

export const endUserIdentityGet = async (arg) => {
    const name = 'End User Identity GET';
    let result;

    const { params } = arg

    const body = {
        "service": "EndUserOnboardIdentidadBOGet",
        "params": params ? params : {}
    }

    try {
        result = await axiosPost('Y', body, arg)
    } catch (err) {
        console.log(err)
    }

    if (!result || result === null) {
        console.log(`Error getting ${name}`)
    }

    return result.data.result[0]
}