import React, { useState, useEffect } from 'react';
// styles
import "../pages.css";
// components
import Header from "../../components/header/Header";
import DatosPersonales from './DatosPersonales/DatosPersonales';
import DatosDeRegistro from './DatosDeRegistro/DatosDeRegistro';
import DatosBiometricos from './DatosBiometricos/DatosBiometricos';
// recoil
import { useSetRecoilState } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import Suscripciones from './Suscripciones/Suscripciones';

function UsuarioActivo({ route }) {
    const [selected, setSelected] = useState(1);
    const pathname = window.location.pathname;
    const id = pathname.substring(10);
    const setRow = useSetRecoilState(rowSelected);

    useEffect(() => setRow({ status: false }), [selected])

    return (
        <>
            <Header route={route} />
            <main className="container-usuario">
                <div className="container-pantallas-usuario">
                    <div className={selected === 1 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(1)}>Datos personales</div>
                    <div className={selected === 2 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(2)}>Datos de registro</div>
                    <div className={selected === 3 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(3)}>Datos biometricos</div>
                    <div className={selected === 4 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(4)}>Datos financieros</div>
                    <div className={selected === 5 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(5)}>Suscripciones</div>
                </div>
                {selected === 1 && <DatosPersonales />}
                {selected === 2 && <DatosDeRegistro />}
                {selected === 3 && <DatosBiometricos />}
                {selected === 5 && <Suscripciones />}
            </main>
        </>
    )
};

export default UsuarioActivo