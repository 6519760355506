import axiosPost from '../axiosPost';

export const zonasDeNoEnrollUpd = async (arg) => {
    const { id, nombre, coordenadas, version } = arg;

    const body = {
        "service": "ZonaNoEnrollUpd",
        "params": {
            'zonaNoEnrollId': id,
            "zonaNoEnrollNombre": nombre,
            "zonaNoEnrollCoordenadas": coordenadas,
            "zonaNoEnrollVersion": version
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}