// services
import { eventosDeSesionIns } from '../../../../services/hooli-services/EventosDeSesion/EventosDeSesionIns';
import { eventosDeSesionDel } from '../../../../services/hooli-services/EventosDeSesion/EventosDeSesionDel';
import { eventosDeSesionUpd } from '../../../../services/hooli-services/EventosDeSesion/EventosDeSesionUpd';

export async function postEvento(credentials, newEvento, setInRequest) {
    if (Object.keys(newEvento).length > 0) {
        const payload = {
            ...newEvento,
            ...credentials
        };

        setInRequest(true);
        const response = await eventosDeSesionIns(payload);
        return response
    }
};

export async function deleteEvento(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await eventosDeSesionDel(payload);
    return response
};

export async function updateEvento(credentials, newEvento, row, setInRequest) {
    if (Object.keys(newEvento).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newEvento,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await eventosDeSesionUpd(payload);
        return response
    }
};