import axiosPost from '../axiosPost';

export default async function endUserNoUserByIdGet(arg) {
    const { params } = arg

    const body = {
        "service": "UsuarioNoUsuarioGet",
        "params": params
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
};