// services
import { condicionesAFIPIns } from '../../../../services/hooli-services/CondicionesAFIP/CondicionesAFIPIns';
import { condicionesAFIPDel } from '../../../../services/hooli-services/CondicionesAFIP/CondicionesAFIPDel';
import { condicionesAFIPUpd } from '../../../../services/hooli-services/CondicionesAFIP/CondicionesAFIPUpd';

export async function postCondicion(credentials, newCondicion, setInRequest) {
    if (Object.keys(newCondicion).length > 0) {
        const payload = {
            ...newCondicion,
            ...credentials
        };

        setInRequest(true);
        const response = await condicionesAFIPIns(payload);
        return response
    }
};

export async function deleteCondicion(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await condicionesAFIPDel(payload);
    return response
};

export async function updateCondicion(credentials, newCondicion, row, setInRequest) {
    if (Object.keys(newCondicion).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newCondicion,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await condicionesAFIPUpd(payload);
        return response
    }
};