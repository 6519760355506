import axiosPost from '../axiosPost';

export const aseguradoraUpd = async (arg) => {
    const { id, aseguradoraNombre, aseguradoraCod, aseguradoraHooli, version, imagePathASEGURA } = arg;

    const body = {
        "service": "AseguradoraUpd",
        "params": {
            "aseguradoraId": id,
            "aseguradoraNombre": aseguradoraNombre,
            "aseguradoraCod": aseguradoraCod,
            "aseguradoraHooli": aseguradoraHooli,
            "aseguradoraVersion": version,
            "aseguradoraImg": imagePathASEGURA
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}