import axiosPost from '../axiosPost';

export const monedasUpd = async (arg) => {
   const { id, nombre, codigo, esBase, version } = arg;

    const body = {
        "service": "MonedaUpd",
        "params": {
            "monedaId": id,
            "monedaNombre": nombre,
            "monedaCod": codigo,
            "monedaEsBase": esBase,
            "monedaVersion": version,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}