import { makeStyles } from '@material-ui/core/styles';

export const FiltrosStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5em',
    },
    filtros: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        marginRight: '1.5rem'
    },
    inputContainer: {
        position: 'relative'
    },
    input: {
        flexDirection: 'row',
        marginRight: '1.5rem',
        '& .MuiInputLabel-formControl': {
            transform: 'translateY(22px)',
            marginRight: '1rem',
            position: 'static!important',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-root': {
            width: '140px'
        },
    },
    iconoBorrar: {
        height: '16px',
        width: '16px',
        position: 'absolute',
        right: '1.5rem',
        bottom: '16px',
        cursor: 'pointer'
    },
    total: {
        margin: 0,
        height: '4rem', 
        display: 'flex', 
        alignItems: 'center',
        flexGrow: 1,
        paddingBottom: '6px',
        boxSizing: 'border-box',
    },
    marginNormal: {
        height: '48px',
        marginBottom: '8px',
        marginTop: '16px',
        display: 'flex',
        alignItems: 'flex-end'
    }
}));