import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from "../../components/utils/buttonsContainer/Buttons";
// consts
import { feriados as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoFeriados as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { feriadosGet } from '../../services/hooli-services/Feriados/FeriadosGet';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { rowSelected, userCredentials, formData } from '../../recoilState/GlobalState';

export default function Feriados() {
    const [feriados, setFeriados] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);

    const setRow = useSetRecoilState(rowSelected)
    const setFormInfo = useSetRecoilState(formData)
    const credentials = useRecoilValue(userCredentials);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, feriadosGet, setFeriados);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        feriados.map((item) => {
            const {
                feriadoId,
                feriadoNombre,
                feriadoVersion,
            } = item

            const feriado = {
                id: feriadoId,
                version: feriadoVersion,
                [titles[0]]: feriadoNombre,
            }

            setRows(rows => [...rows, feriado])
            return item
        })
    }, [feriados]);

    useEffect(() => setRow({ status: false, row: {} }), []);

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action])
        setAction({ action, procede });
    };

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons texto="feriado" handleClick={handleClick} updateList={updateList} />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};