import axiosPost from '../axiosPost';

export const preonboardUsuarioIns = async (arg) => {
    const { usuarioNroDeDocumento,
        usuarioVtoDeDocumento,
        usuarioApellidos,
        usuarioNombres,
        usuarioFechaDeNacimiento,
        usuarioSexo,
        domicilioCalle,
        domicilioCasa,
        domicilioPiso,
        domicilioUnidad,
        domicilioCPA,
        domicilioScan,
        localidadId,
        usuarioDNIMatchRENAPER,
        usuarioCUIT,
        usuarioDNITramite,
        documentoFrenteImg,
        documentoDorsoImg,
        documentoFotoImg,
        usuarioFotoImg,
        usuarioDNIMatchScore,
        idxId
     } = arg;

     const body = {
        "service": "UsuarioPreonboardManualIns",
        "params": {
            "usuarioIdxId": idxId,
            "usuarioDeviceId": `Sin device ID ${usuarioNroDeDocumento}`,
            "usuarioGCMToken": `Sin GCM Token ${usuarioNroDeDocumento}`,
            "usuarioEquipoMarca": "Sin identificar",
            "usuarioEquipoModelo": "Sin identificar",
            "usuarioAppVersion": "0.0.0",
            "tipoDeDocumentoId": 1,
            usuarioNroDeDocumento,
            usuarioVtoDeDocumento,
            usuarioApellidos: usuarioApellidos.substring(0, 1).toUpperCase() + usuarioApellidos.substring(1).toLowerCase(),
            usuarioNombres,
            usuarioFechaDeNacimiento,
            usuarioSexo,
            domicilioCalle,
            domicilioCasa,
            domicilioPiso,
            domicilioUnidad,
            domicilioCPA,
            domicilioScan,
            localidadId,
            statusOnBoardDomicilio: "OK",
            usuarioDNIFMR: 0,
            usuarioDNIMatchScore,
            usuarioDNITransactionId: "Verificación visual",
            usuarioDNIMatchRENAPER,
            statusOnBoardOCRMRZ: null,
            statusOnBoardDocumento: "OK",
            statusOnBoardRENAPER: usuarioDNIMatchRENAPER < 0.4 ? 'BO' : "OK",
            usuarioCUIT,
            statusOnBoardCUIT: "OK",
            usuarioDNITramite: null,
            usuarioDNITramiteRENAPER: usuarioDNITramite,
            documentoFrenteImg,
            documentoDorsoImg,
            documentoFotoImg,
            usuarioFotoImg,
            "usuarioFinanScore": null,
            "registroXUsuarioLatitud": -33.123456,
            "registroXUsuarioLongitud": -55.123456,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.data
    } catch (err) {
        console.log(err)
    }
}