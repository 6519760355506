import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// consts
import { merchants as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoMerchants as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { merchantsGet } from '../../services/hooli-services/Merchants/MerchantsGet';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function Merchants() {
    const [merchants, setMerchants] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);

    const setRow = useSetRecoilState(rowSelected)
    const setFormInfo = useSetRecoilState(formData)
    const credentials = useRecoilValue(userCredentials);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, merchantsGet, setMerchants)
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        merchants.map((item) => {
            const {
                merchantId,
                merchantNombre,
                merchantCUIT,
                planNombre,
                rubroMerchantNombre,
                merchantStatusAcreditacionesNombre,
                merchantVersion
            } = item

            const merchant = {
                id: merchantId,
                version: merchantVersion,
                [titles[0]]: merchantNombre,
                [titles[1]]: merchantCUIT,
                [titles[2]]: planNombre,
                [titles[3]]: rubroMerchantNombre,
                [titles[4]]: merchantStatusAcreditacionesNombre
            }

            setRows(rows => [...rows, merchant])
            return item
        });
    }, [merchants]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action])
        setAction({ action, procede });
    };

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons texto="merchant" handleClick={handleClick} updateList={updateList} />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};