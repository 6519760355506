// services
import { zonasDeNoEnrollIns } from '../../../../services/hooli-services/ZonasDeNoEnroll/ZonasDeNoEnrollIns';
import { zonasDeNoEnrollDel } from '../../../../services/hooli-services/ZonasDeNoEnroll/ZonasDeNoEnrollDel';
import { zonasDeNoEnrollUpd } from '../../../../services/hooli-services/ZonasDeNoEnroll/ZonasDeNoEnrollUpd';

export async function postZone(credentials, newZone, setInRequest) {
    if (Object.keys(newZone).length > 0) {
        const payload = { ...newZone, ...credentials };

        setInRequest(true);
        const response = await zonasDeNoEnrollIns(payload);
        return response
    }
};

export async function deleteZone(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await zonasDeNoEnrollDel(payload);
    return response
};

export async function updateZone(credentials, newZone, row, setInRequest) {
    if (Object.keys(newZone).length > 0) {
        const { nombre } = newZone;
        const { id, version, coordenadas } = row;

        const payload = {
            id,
            nombre,
            coordenadas,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await zonasDeNoEnrollUpd(payload);
        return response
    }
};