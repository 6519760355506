import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
// forms
import AseguradorasForm from '../form/Aseguradoras/AseguradorasForm';
import LocalidadesForm from '../form/Localidades/LocalidadesForm';
import CondicionesAFIPForm from '../form/CondicionesAFIP/CondicionesAFIPForm';
import TiposDeDomicilioForm from '../form/TiposDeDomicilio/TiposDeDomicilioForm';
import TiposDeImagenForm from "../form/TiposDeImagen/TiposDeImagenForm";
import FormatosDeImagenForm from "../form/FormatosDeImagen/FormatosDeImagenForm";
import FormatosXTipoDeImagenForm from "../form/FormatosXTipoDeImagen/FormatosXTipoDeImagenForm";
import MonedasForm from '../form/Monedas/MonedasForm';
import CotizacionesDeMonedasForm from '../form/CotizacionesDeMonedas/CotizacionesDeMonedasForm';
import FeriadosForm from '../form/Feriados/FeriadosForm';
import SerieDeFeriadosForm from '../form/SerieDeFeriados/SerieDeFeriadosForm';
import RegistrosForm from '../form/Registros/RegistrosForm';
import TiposDeRegistroForm from '../form/TiposDeRegistro/TiposDeRegistroForm';
import EstadosDeRegistroForm from "../form/EstadosDeRegistro/EstadosDeRegistroForm";
import EventosDeSesionForm from "../form/EventosDeSesion/EventosDeSesionForm";
import TiposDeDeviceForm from "../form/TiposDeDevice/TiposDeDeviceForm";
import ZonasDeNoEnrollForm from '../form/ZonasDeNoEnroll/ZonasDeNoEnrollForm';
import MerchantsForm from "../form/Merchants/MerchantsForm";
import RubrosDeMerchantForm from '../form/RubrosDeMerchant/RubrosDeMerchantForm';
import CargosForm from "../form/Cargos/CargosForm";
import PreonboardingManualForm from "../form/PreonboardingManual/PreonboardingManualForm";
//otros modales
import EndUserModal from "../../../pages/onBoardingEndUser/EndUserModal";
import IdentityModal from "../../../pages/onBoardingEndUser/Identity/IdentityModal";
import LocationModal from "../../../pages/onBoardingEndUser/Location/LocationModal";
import CuilCuitModal from "../../../pages/onBoardingEndUser/CUIL-CUIT/Cuil-CuitModal";
import MapaModal from "../../../pages/onBoardingEndUser/Location/MapaModal";
import ModalInfoMerchant from "../../../pages/onBoardingMerchant/ModalInfoMerchant";
import ModalRecorteImagen from "../../../pages/preonboardingManual/ModalRecorteImagen";
// recoil 
import { useRecoilValue } from 'recoil'
import { formIsOpen, routeInfo } from '../../../recoilState/GlobalState';

import { ModalsStyles } from './Modals.styles';

export default function TransitionModal(props) {
    const classes = ModalsStyles();
    const isOpen = useRecoilValue(formIsOpen);
    const { wordKey: route } = useRecoilValue(routeInfo);

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            open={isOpen}
        >
            <Fade in={isOpen} className={classes.modal}>
                <div className={classes.modal}>
                    <div className={classes.paper}>
                        {route === 'Compañias aseguradoras' && <AseguradorasForm props={props} />}
                        {route === 'Localidades' && <LocalidadesForm props={props} />}
                        {route === 'Condiciones AFIP' && <CondicionesAFIPForm props={props} />}
                        {route === 'Tipos de domicilio' && <TiposDeDomicilioForm props={props} />}
                        {route === 'Tipos de imagen' && <TiposDeImagenForm props={props} />}
                        {route === 'Formatos de imagen' && <FormatosDeImagenForm props={props} />}
                        {route === 'Formatos por tipo de imagen' && <FormatosXTipoDeImagenForm props={props} />}
                        {route === 'Monedas' && <MonedasForm props={props} />}
                        {route === 'Cotizaciones de Monedas' && <CotizacionesDeMonedasForm props={props} />}
                        {route === 'Feriados' && <FeriadosForm props={props} />}
                        {route === 'Series de feriados' && <SerieDeFeriadosForm props={props} />}
                        {route === 'Registros' && <RegistrosForm props={props} />}
                        {route === 'Tipos de registro' && <TiposDeRegistroForm props={props} />}
                        {route === 'Estados de registro' && <EstadosDeRegistroForm props={props} />}
                        {route === 'Eventos de sesion' && <EventosDeSesionForm props={props} />}
                        {route === 'Tipos de device' && <TiposDeDeviceForm props={props} />}
                        {route === 'Zonas de no enroll' && <ZonasDeNoEnrollForm props={props} />}
                        {route === 'Merchant' && <ModalInfoMerchant />}
                        {route === 'Merchants' && <MerchantsForm props={props} />}
                        {route === 'Rubros de merchant' && <RubrosDeMerchantForm props={props} />}
                        {route === 'Cargos' && <CargosForm props={props} />}
                        {route === 'End User' && props.procede === undefined ?
                            <EndUserModal props={props} /> :
                            // props.procede === 1 ||
                            props.procede === 2 ||
                                props.procede === 3 ||
                                props.procede === 4 ?
                                <IdentityModal props={props} /> :
                                props.procede === 5 ?
                                    <LocationModal props={props} /> :
                                    props.procede === 7 ?
                                        <CuilCuitModal props={props} /> :
                                        props.procede === 'location-mapa' ? <MapaModal props={props} /> : null}
                        {route === 'Preonboarding Manual' || 'Usuarios No Usuarios' ? props.procede === 'recorte' ?
                            <ModalRecorteImagen props={props} />
                            : <PreonboardingManualForm props={props} />
                            : null
                        }
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
