import { makeStyles } from "@material-ui/core/styles";

export const ListDrawerStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "2rem",
    },
    nested: {
        paddingLeft: theme.spacing(4),
        display: "flex",
    },
    iconsDrawer: {
        width: "1.6rem",
    },
    itemList: {
        paddingBottom: theme.spacing(2),
    },
    link: {
        textDecoration: 'none'
    },
    textColor: {
        color: "#ffffff",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "300",
        fontSize: "0.85rem",
        flexGrow: 1
    },
}));