// services
import { monedasIns } from '../../../../services/hooli-services/Monedas/MonedasIns';
import { monedasDel } from '../../../../services/hooli-services/Monedas/MonedasDel';
import { monedasUpd } from '../../../../services/hooli-services/Monedas/MonedasUpd';

export async function postMoneda(credentials, newMoneda, setInRequest) {
    if (Object.keys(newMoneda).length > 0) {
        const payload = {...newMoneda, ...credentials};

        setInRequest(true);
        const response = await monedasIns(payload);
        return response
    }
};

export async function deleteMoneda(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await monedasDel(payload);
    return response
};

export async function updateMoneda(credentials, newMoneda, row, setInRequest) {
    if (Object.keys(newMoneda).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newMoneda,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await monedasUpd(payload);
        return response
    }
};