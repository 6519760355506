import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import { FormikSelectInput, FormikTextInput } from "../../inputs/FormikInputs";
import CommonForm from "../CommonForm";
// functions
import { postLocalidad, deleteLocalidad, updateLocalidad } from './LocalidadesFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './LocalidadesFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function LocalidadesForm(props) {
    const [newLocalidad, setNewLocalidad] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede, provincias } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteLocalidad(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Localidad eliminada exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postLocalidad(credentials, newLocalidad, setInRequest)
            return handleResponse(response, 'Localidad añadida exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateLocalidad(credentials, newLocalidad, selected.row, setInRequest)
            return handleResponse(response, 'Localidad modificada exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newLocalidad]);

    const formik = formikValidation(setNewLocalidad, selected.row, titles);
    const disabled = inRequest || procede === 'Deleteone';

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikSelectInput
                data={data}
                name="provincia"
                formik={formik}
                disabled={procede === 'Addone' ? inRequest : true}
                options={provincias}
                optionKey="provinciaId"
                optionValue="provinciaId"
                optionName="provinciaNombre"
            />
            <FormikTextInput
                data={data}
                name='nombre'
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};