import axiosPost from '../axiosPost';

export const condicionesAFIPGet = async (arg) => {
    const { params } = arg

    const body = {
        "service": "CondicionAFIPGet",
        "params": params ? params : {}
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
}