import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { XGrid, LicenseInfo } from "@material-ui/x-grid";
import { XGridStyles } from "./Xgrid.styles";
// functions
import { generateColumns, getGridProps } from '../../../services/Local-services';
// recoil
import { useRecoilValue, useRecoilState } from 'recoil'
import { routeInfo, rowSelected } from '../../../recoilState/GlobalState';

LicenseInfo.setLicenseKey(
    "566251e0a8fd26c8758bbc5c1f9df178T1JERVI6MjE5MTUsRVhQSVJZPTE2NDYyMjIwNTcwMDAsS0VZVkVSU0lPTj0x"
);

export default function XGridDemo({ rows, titles, secondarySelected, setSecondarySelected }) {
    const [page, setPage] = useState(0);
    const [inputValue, setInputValue] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const [selected, setSelected] = useRecoilState(rowSelected);
    const { route } = useRecoilValue(routeInfo);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue === '' || parseInt(inputValue) < 1) {
            setPage(0);
            return setInputValue(1);
        };
        if (parseInt(inputValue) > numberOfPages) {
            setPage(numberOfPages);
            return setInputValue(numberOfPages + 1);
        }
        return setPage(parseInt(inputValue) - 1);
    };

    const columns = generateColumns(titles, route)
    const { row } = secondarySelected ? secondarySelected : selected;
    const setter = setSecondarySelected ? setSecondarySelected : setSelected;
    const classes = XGridStyles();

    const gridProps = getGridProps(columns, rows, row, setter, classes);

    return (
        <>
            <XGrid
                {...gridProps}
                page={page}
                onPageChange={(newPage) => {
                    setInputValue(newPage.page)
                    setPage(newPage.page)
                }}
                onStateChange={(data) => setNumberOfPages(data.state.pagination.pageCount - 1)}
            />
            <form className={classes.paginador} onSubmit={(e) => handleSubmit(e)}>
                <TextField value={inputValue} type="number" max={numberOfPages} onChange={(e) => setInputValue(e.target.value)} />
            </form>
        </>
    )
};