import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    codigo: yup.string().uppercase('Debe ser todo mayusculas').required("Ingrese un codigo"),
    descripcion: yup.string().required("Ingrese una descripcion"),
    bo: yup.string().required("Seleccione una opcion"),
    tipoDeRegistro: yup.string().required('Seleccione una opcion')
});

export const formikValidation = (setNewRegistro, row, titles, tiposDeRegistro) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        codigo: row && row[titles[1]] ? row[titles[1]] : "",
        descripcion: row && row[titles[2]] ? row[titles[2]] : "",
        bo: row && row.requiereBO ? row.requiereBO : "Y",
        tipoDeRegistro: row && row.tipoDeRegistro ? row.tipoDeRegistro : tiposDeRegistro[0].tipoRegistroId
    },
    validationSchema,
    onSubmit: (values) => setNewRegistro(values)
});