import HeaderButton from "../components/utils/button/HeaderButton";
import { TextField, Badge } from "@material-ui/core";
import { fallbackURL } from "../consts/FallbackURL";

export const refreshPage = () => window.location.reload()

export const lettersOnly = (value) => /^(?=s*[a-zA-Z])([a-zA-Z0-9\s]+)$/.test(value);

export const transformDNI = (value) => new Intl.NumberFormat('es-ES').format(value).replace(/,/g, '.');

export const logout = () => {
    if (localStorage.getItem('hooliToken') && localStorage.getItem('sessionId')) {
        localStorage.removeItem('hooliToken');
        localStorage.removeItem('sessionId');
        window.location.assign(fallbackURL)
    }
};

export const handleResponse = (response, message, functions, toArray) => {
    const { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList } = functions

    if (response) {
        if (response.status.code === 1) {
            setSnackbarInfo({
                message,
                severity: 'success',
                open: true
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
                setIsOpen(false)
                toArray ? setSelected([]) : setSelected({ status: false, row: {} });
            }, 3000);
            updateList();
        }
        else {
            setSnackbarInfo({
                message: response.status.errmsg,
                severity: response.status.action === 'W' ? 'warning' : 'error',
                open: true
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
        }
    }
};

export const getGridProps = (columns, rows, row, setter, classes) => {
    return {
        localeText: {
            columnMenuLabel: "Menu",
            columnMenuShowColumns: "Mostrar columnas",
            columnMenuFilter: "Filtrar",
            columnMenuHideColumn: "Esconder",
            columnMenuUnsort: "Desordenar",
            columnMenuSortAsc: "Ordenar por ASC",
            columnMenuSortDesc: "Ordenar por DESC",
        },
        columns,
        rows,
        onRowClick: (data) => validateRowSelected(data, row, setter),
        className: classes.root,
        autoPageSize: true,
        hideFooterSelectedRowCount: true,
        disableMultipleSelection: true,
        pagination: true,
        showCellRightBorder: false,
        rowHeight: 36
    }
}

export const validateRowSelected = (newSelection, row, setSelected) => {
    if (row && row.id === newSelection.row.id) {
        newSelection.element.classList.remove('Mui-selected')
        return setSelected({ status: false, row: {} });
    }
    const newSelected = { status: true, row: newSelection.row }
    newSelection.element.classList.add('Mui-selected')
    return setSelected(newSelected)
};

export const generateButtons = (conditional, wordkey, handler, icons, id, updateList) => {
    return conditional ?
        <>
            <HeaderButton
                text={`Editar ${wordkey}`}
                icon={icons[0]}
                handleClick={handler}
                data={{ action: 'edit', procede: 'Editone' }}
                id={wordkey === 'End User' ? id : ''}
            />
            <HeaderButton
                text={`Eliminar ${wordkey}`}
                icon={icons[1]}
                handleClick={handler}
                data={{ action: 'delete', procede: 'Deleteone' }}
            />
        </>
        :
        <>
            <HeaderButton
                text={`Agregar ${wordkey}`}
                icon={icons[2]}
                handleClick={handler}
                data={{ action: 'add', procede: 'Addone' }}
            />
            <HeaderButton
                text={"Actualizar pagina"}
                icon={icons[3]}
                updateList={updateList}
            />
        </>
};

export const generateColumns = (titles) => {
    return titles.reduce((acc, title) => {
        return [...acc, {
            field: title,
            headerName: title,
            flex: 1,
            renderCell: (params) => {
                if (title === 'Status de registro') {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {params.value}
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <Badge badgeContent={params.row.aprobados} color={'primary'}></Badge>
                                <Badge badgeContent={params.row.enEspera} color={'secondary'}></Badge>
                                <Badge badgeContent={params.row.rechazados} color={'error'}></Badge>
                            </div>
                        </div>
                    )
                }
            },
            valueGetter: (params) => {
                if (!params.value) return
                if (/Fecha/.test(title)) return transformDate(params.value.substring(0, 10));
                return params.value
            },
            sortComparator: (v1, v2) => {
                if (typeof v1 === 'number') return v1 - v2;
                if (/\d{4}-\d{2}-\d{2}/.test(v1) && /\d{4}-\d{2}-\d{2}/.test(v2)) return parseInt(v1.replace(/-/g, '')) - parseInt(v2.replace(/-/g, ''));

                let first = "";
                for (let i = 0; i < v1.length; i++) {
                    first += v1[i].charCodeAt(0).toString(2) + " ";
                }
                let second = "";
                for (let i = 0; i < v2.length; i++) {
                    second += v2[i].charCodeAt(0).toString(2) + " ";
                }
                return parseInt(first, 2) - parseInt(second, 2)
            }
        }]
    }, [])
};

export const retrieveData = async (credentials, setRows, getData, setData) => {
    if (setRows) setRows([])

    const data = await getData(credentials);
    setData(data)
};

export const transformDate = (str) => `${str.substring(8)}/${str.substring(5, 7)}/${str.substring(0, 4)}`;

export const generateDeleteInputs = (data, names, selected, titles) => {
    return <>
        {names.map((name, index) => {
            return <TextField
                key={data[`${name}`].label}
                label={data[`${name}`].label}
                disabled
                name={name}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                value={selected.row ? selected.row[`${titles[index]}`] : ''}
                fullWidth
            />
        })}
    </>
};

export const generateImage = (selected, path, src, alt) => {
    return (
        <div className="form__image-container">
            <img
                src={selected.row && selected.row[path] && !src ?
                    selected.row[path] : src ? `data:image/png;base64,${src}` : null}
                alt={alt}
                style={{ height: (selected.row && selected.row[path]) || src ? 90 : 0, maxWidth: 300 }}
            />
        </div>
    )
};

export const generateTextInput = (data, name, formik, openAlert) => {
    return (
        <TextField
            label={data[`${name}`].label}
            name={name}
            placeholder={data[`${name}`].placeholder}
            margin="normal"
            disabled={openAlert}
            value={formik.values[`${name}`]}
            onChange={formik.handleChange}
            error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
            helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
            InputLabelProps={{ shrink: true }}
            fullWidth
        />
    )
};

export const generateDateInput = (data, name, formik, openAlert) => {
    return (
        <TextField
            label={data[`${name}`].label}
            name={name}
            margin="normal"
            type="date"
            disabled={openAlert}
            value={formik.values[`${name}`]}
            onChange={formik.handleChange}
            error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
            helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
            InputLabelProps={{ shrink: true }}
            fullWidth
        />
    )
};

export const generateYesOrNoSelect = (data, name, formik, openAlert) => {
    return (
        <TextField
            label={data[`${name}`].label}
            name={name}
            placeholder={data[`${name}`].placeholder}
            margin="normal"
            select
            disabled={openAlert}
            SelectProps={{ native: true }}
            value={formik.values[`${name}`]}
            onChange={formik.handleChange}
            error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
            helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
            fullWidth
        >
            <option value="Si">Si</option>
            <option value="No">No</option>
        </TextField>
    )
};

export const generateSelect = (data, name, formik, isDisabled, options, optionId, optionValue) => {
    return (
        <TextField
            label={data[`${name}`].label}
            name={name}
            placeholder={data[`${name}`].placeholder}
            margin="normal"
            select
            disabled={isDisabled}
            SelectProps={{ native: true }}
            value={formik.values[`${name}`]}
            onChange={formik.handleChange}
            error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
            helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
            fullWidth
        >
            {options.map(option => {
                return <option
                    key={option[`${optionId}`]}
                    value={option[`${optionValue}`]}
                >
                    {option[`${optionValue}`]}
                </option>
            })}
        </TextField>
    )
};

export const handleImg = (e, setImg, readerHandler) => {
    let file = e.target.files[0]
    if (file) {
        setImg({ alt: file.name })
        const reader = new FileReader();
        reader.onload = readerHandler;
        reader.readAsBinaryString(file);
    }
};