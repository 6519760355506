import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    codigo: yup.string().max(6, "Debe tener maximo 6 letras").uppercase('Debe ser todo mayusculas').required("Ingrese un codigo"),
});

export const formikValidation = (setNewEvento, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        codigo: row && row[titles[1]] ? row[titles[1]] : "",
    },
    validationSchema,
    onSubmit: (values) => setNewEvento(values)
});