import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formIsOpen, rowSelected } from '../../recoilState/GlobalState';
import classes from './ModalInfoMerchant.module.css';

export default function ModalInfoMerchant() {
    const { row } = useRecoilValue(rowSelected);
    const setOpen = useSetRecoilState(formIsOpen);
    console.log(row)
    return (
        <div className={classes.container}>
            <h2 className={classes.title}>{row.merchantNombre}</h2>
            <div className={classes.grid}>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Administrador</div>
                    <div className={classes.info}>{`${row.usuarioApellidos}, ${row.usuarioNombres}`}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Razón Social</div>
                    <div className={classes.info}>{row.merchantRZ}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>CUIT</div>
                    <div className={classes.info}>{row.merchantCUIT}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Fecha de alta</div>
                    <div className={classes.info}>{row.merchantFechaAlta}</div>
                </div>
                {/*  */}
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Condicion AFIP</div>
                    <div className={classes.info}>{row.condicionAFIPNombre}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Condicion IVA</div>
                    <div className={classes.info}>{row.merchantCondicionIVANombre}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Condicion IIBB</div>
                    <div className={classes.info}>{row.merchantIBNombre}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Numero IIBB</div>
                    <div className={classes.info}>{row.merchantIBNumero}</div>
                </div>
                {/*  */}
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Rubro</div>
                    <div className={classes.info}>{row.rubroMerchantNombre}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Plan</div>
                    <div className={classes.info}>{row.planNombre}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Persona F / J</div>
                    <div className={classes.info}>{row.merchantPFPJLeyenda}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Provincia</div>
                    <div className={classes.info}>{row.provinciaNombre}</div>
                </div>
                {/*  */}
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Celular</div>
                    <div className={classes.info}>{row.merchantCelular}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Telefono</div>
                    <div className={classes.info}>{row.merchantTelefono}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Mail</div>
                    <div className={classes.info}>{row.merchantMail}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Website</div>
                    <div className={classes.info}>{row.merchantWebsite}</div>
                </div>
                {/*  */}
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Cash</div>
                    <div className={classes.info}>{row.merchantCash === 'Y' ? 'Sí' : 'No'}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Unipersonal</div>
                    <div className={classes.info}>{row.merchantUnipersonal === 'Y' ? 'Sí' : 'No'}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>A Domicilio</div>
                    <div className={classes.info}>{row.merchantADomicilio === 'Y' ? 'Sí' : 'No'}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Primera compra</div>
                    <div className={classes.info}>{row.merchantPrimeraCompra === 'Y' ? 'Sí' : 'No'}</div>
                </div>
                {/*  */}
                <div className={classes.cell}>
                    <div className={classes.subtitle}>CVU</div>
                    <div className={classes.info}>{row.merchantCVU}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>CVU Alias</div>
                    <div className={classes.info}>{row.merchantCVUAlias}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Status acreditación</div>
                    <div className={classes.info}>{row.merchantStatusAcreditacionesNombre}</div>
                </div>
                <div className={classes.cell}>
                    <div className={classes.subtitle}>Status POS</div>
                    <div className={classes.info}>{row.merchantStatusPOSNombre}</div>
                </div>
            </div>
            {row.imagePathLOGO &&
                <div className={classes.imageContainer}>
                    <img src={row.imagePathLOGO} alt="Logo merchant" className={classes.image} />
                </div>
            }
            <div className={classes.closeButtonContainer}>
                <button className={classes.button} onClick={() => setOpen(false)}>Cerrar</button>
            </div>
        </div>
    )
};