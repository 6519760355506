import axiosPost from '../axiosPost';

export const tiposDeRegistroDel = async (arg) => {
    const { id } = arg;

    const body = {
        "service": "TipoDeRegistroDel",
        "params": { "tipoRegistroId": id }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}