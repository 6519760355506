// services
import { localidadesIns } from '../../../../services/hooli-services/Localidades/LocalidadesIns';
import { localidadesDel } from '../../../../services/hooli-services/Localidades/LocalidadesDel';
import { localidadesUpd } from '../../../../services/hooli-services/Localidades/LocalidadesUpd';

export async function postLocalidad(credentials, newLocalidad, setInRequest) {
    if (Object.keys(newLocalidad).length > 0) {
        const payload = {
            ...newLocalidad,
            ...credentials
        };

        setInRequest(true);
        const response = await localidadesIns(payload);
        return response
    }
};

export async function deleteLocalidad(credentials, row, setInRequest) {
    const { id } = row
    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await localidadesDel(payload);
    return response
};

export async function updateLocalidad(credentials, newLocalidad, row, setInRequest) {
    if (Object.keys(newLocalidad).length > 0) {
        const { nombre } = newLocalidad;
        const { id, version } = row;

        const payload = {
            id,
            nombre,
            version,
            ...credentials
        }

        setInRequest(true);
        const response = await localidadesUpd(payload);
        return response
    }
};