import React from 'react';
import HeaderButton from '../button/HeaderButton';
// assets
import addIcon from "../../../assets/icons/rediseño.svg";
import reload from "../../../assets/icons/actualizar.svg";
import editar from "../../../assets/icons/editar.svg";
import eliminar from "../../../assets/icons/eliminar.svg";
// styles
import classes from './Buttons.module.css';
// Recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../../recoilState/GlobalState';

export default function Buttons({ texto, handleClick, updateList, children }) {
    const { status } = useRecoilValue(rowSelected);
    return (
        <div className={classes.buttonsContainer}>
            <div className={classes.icons}>
                {children ? children :
                    status ?
                        <>
                            < HeaderButton
                                text={`Editar ${texto}`}
                                icon={editar}
                                handleClick={handleClick}
                                data={{ action: 'edit', procede: 'Editone' }}
                            />
                            <HeaderButton
                                text={`Eliminar ${texto}`}
                                icon={eliminar}
                                handleClick={handleClick}
                                data={{ action: 'delete', procede: 'Deleteone' }}
                            />
                        </>
                        :
                        <>
                            <HeaderButton
                                text={`Agregar ${texto}`}
                                icon={addIcon}
                                handleClick={handleClick}
                                data={{ action: 'add', procede: 'Addone' }}
                            />
                            <HeaderButton
                                text={"Actualizar pagina"}
                                icon={reload}
                                updateList={updateList}
                            />
                        </>
                }
            </div>
        </div >
    )
};