import axiosPost from '../axiosPost';

export const cotizacionesDeMonedasUpd = async (arg) => {
    const { id, cantidad, comprador, vendedor, version } = arg;

    const body = {
        "service": "MonedaSerieUpd",
        "params": {
            "monedaSerieId": id,
            "monedaSerieQMoneda": cantidad,
            "monedaSerieValorComprador": comprador,
            "monedaSerieValorVendedor": vendedor,
            "monedaSerieVersion": version,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.data
    } catch (err) {
        console.log(err)
    }
}