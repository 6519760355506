import React from "react";
// styles
import classes from "./dashboard.module.css";
// assets
import Elipse from "../../assets/icons/elipse.svg";
import Rombo from "../../assets/icons/rombo.svg";
import Trazado from "../../assets/icons/trazado.svg";
import Wheel from "../../assets/icons/media-rueda.svg";
import Cross from "../../assets/icons/cruz.svg";

export default function Dashboard() {
    return (
        <main >
            <div className={classes.mainContainer}>
                <div className={classes.figures}>
                    <div className={classes.elipse}>
                        <img src={Elipse} alt={classes.elipse} />
                        <img className={classes.rombo} src={Rombo} alt="rombo" />
                    </div>
                    <div className={classes.verticalLine}>
                        <img className={classes.line} src={Trazado} alt="linea" />
                    </div>
                </div>
                <section className={classes.section}>
                    <h1>¡Te damos la bienvenida a Backoffice Hooli!</h1>
                </section>
                <div className={classes.figuresLeft}>
                    <div className={classes.containerLeft}>
                        <div className={classes.halfWheel}>
                            <img src={Wheel} alt="rueda" />
                        </div>
                        <div className={classes.cross}>
                            <img src={Cross} alt="cruz" />
                        </div>
                        <div className={classes.rombo}>
                            <img src={Rombo} alt="rombo" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
