import React, { useState, useEffect } from "react";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// consts
import { estadosDeRegistro as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoEstadosDeRegistro as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { estadosDeRegistroGet } from '../../services/hooli-services/EstadosDeRegistro/EstadosDeRegistroGet';
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function EstadosDeRegistro() {
    const [estadosDeRegistro, setEstadosDeRegistro] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);

    const [{ status }, setRow] = useRecoilState(rowSelected)
    const setFormInfo = useSetRecoilState(formData);
    const credentials = useRecoilValue(userCredentials);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, estadosDeRegistroGet, setEstadosDeRegistro);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        estadosDeRegistro.map((item) => {
            const {
                estadoDeRegistroId,
                estadoDeRegistroNombre,
                estadoDeRegistroCod,
                estadoDeRegistroVersion,
            } = item

            const estado = {
                id: estadoDeRegistroId,
                version: estadoDeRegistroVersion,
                [titles[0]]: estadoDeRegistroNombre,
                [titles[1]]: estadoDeRegistroCod,
            }

            setRows(rows => [...rows, estado])
            return item
        });
    }, [estadosDeRegistro]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons texto="estado de registro" handleClick={handleClick} updateList={updateList}/>
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};