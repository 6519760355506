import React, { useState } from 'react';
import XGrid from '../../../components/utils/grid/Xgrid';

const titles = ['Fecha', 'Importe', 'Medio de pago', 'Detalle'];
const rows = [
    {
        id: 1,
        'Fecha': '22/09/2020',
        'Importe': '$855',
        'Medio de pago': 'Mastercard 0987',
        'Detalle': 'Aprobado'
    },
    {
        id: 2,
        'Fecha': '22/09/2020',
        'Importe': '$855',
        'Medio de pago': 'Mastercard 0987',
        'Detalle': 'Aprobado'
    },
    {
        id: 3,
        'Fecha': '22/09/2020',
        'Importe': '$855',
        'Medio de pago': 'Mastercard 0987',
        'Detalle': 'Aprobado'
    }
];

function Suscripciones() {
    const [selected, setSelected] = useState(1);
    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <>
            <div className="container-pantallas-usuario" style={{ margin: '1em 0' }}>
                <div className={selected === 1 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(1)}>Plan PRIME</div>
                <div className={selected === 2 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(2)}>Seguro celular</div>
                <div className={selected === 3 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(3)}>Hooli Key</div>
            </div>
            <div className="datos-personales">
                <div className="datos-personales-column" style={{ marginRight: '1em' }}>
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">Estado de la suscripcion</p>
                        <p className="dato-personal-mid">Activo</p>
                    </div>
                </div>
                <div className="datos-personales-column" style={{ marginRight: '1em' }}>
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">Fecha de alta</p>
                        <p className="dato-personal-mid">22/09/2020</p>
                    </div>
                </div>
                <div className="datos-personales-column" style={{ marginRight: '1em' }}>
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">Vencimiento</p>
                        <p className="dato-personal-mid">22/09/2024</p>
                    </div>
                </div>
                <div className="datos-personales-column" style={{ marginRight: '1em' }}>
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">Monto mensual</p>
                        <p className="dato-personal-mid">$990</p>
                    </div>
                </div>
            </div>
            <div className="datos-personales">
                <div className="metodos-de-pago">
                    <p className="titulo-violeta" style={{ marginBottom: '1.5rem' }}>Métodos de pago asignados</p>
                    <div style={{ display: 'flex' }}>
                        <div className="rectangulo-gris" style={{ flexGrow: 1, marginRight: '0.5em', maxWidth: '364px' }}>
                            <p className="dato-personal-label">Primario</p>
                            <p className="dato-personal">Tarjeta de crédito VISA terminada en 0987</p>
                            <p className="dato-personal">Vencimiento 12/21</p>
                        </div>
                        <div className="rectangulo-gris" style={{ flexGrow: 1, marginLeft: '0.5em', maxWidth: '364px' }}>
                            <p className="dato-personal-label">Secundario</p>
                            <p className="dato-personal">Tarjeta de crédito Mastercard terminada en 0987</p>
                            <p className="dato-personal">Vencimiento 12/21</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="rectangulo-gris" style={{ flexGrow: 1, marginRight: '0.5em', maxWidth: '364px' }}>
                            <p className="dato-personal-label">Ultimo pago</p>
                            <p className="dato-personal-mid">20/01/2021 - Aprobado</p>
                        </div>
                        <div className="rectangulo-gris" style={{ flexGrow: 1, marginLeft: '0.5em', maxWidth: '364px' }}>
                            <p className="dato-personal-label">Proximo pago</p>
                            <p className="dato-personal-mid">20/02/2021</p>
                        </div>
                    </div>
                </div>
                <div className="ultimos-movimientos">
                    <p className="titulo-violeta" style={{ marginBottom: '1.5rem' }}>Últimos movimientos</p>
                    <div className="grid-suscripciones" >
                        <XGrid rows={rows} titles={titles} width={gridWidth} indice={0} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Suscripciones