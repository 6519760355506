import React from "react";
// styles
import classes from "./HeaderButton.module.css";
// recoil
import { useSetRecoilState } from 'recoil';
import { formIsOpen } from '../../../recoilState/GlobalState';

export default function CircularButton({ text, icon, handleClick, data, deleteImage, setOpen, id, updateList, customUrl }){
    const setIsOpen = useSetRecoilState(formIsOpen);

    return (
        <div className={`${classes.tooltip} ${classes.bottom}`}>
            <div
                className={classes.buttons}
                onClick={() => {
                    if(text === "Actualizar pagina") return updateList();
                    if(text === "Ver usuario") return window.location.href = `/usuarios/${id}`;
                    if(text === "Ver Detalle") return window.location.href = customUrl;
                    if(handleClick) handleClick(data);
                    if(deleteImage) deleteImage()
                    if(setOpen) setOpen(true);
                    setIsOpen(true);
                }}
            >
                <img src={icon} alt={text} />
                <span className={classes.tiptext}>{text}</span>
            </div>
        </div>
    );
};
