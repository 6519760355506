import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// styles
import "../pages.css";
// components
import Buttons from "../../components/utils/buttonsContainer/Buttons";
import HeaderButton from "../../components/utils/button/HeaderButton";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { usuariosNoUsuarios as titles } from "../../consts/titulos-de-tablas";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import reload from "../../assets/icons/actualizar.svg";
import lupa from "../../assets/icons/lupa.png"
//Services 
import { endUserNoUserGet } from "../../services/hooli-services/EndUser/EndUserNoUserGet";
import { usuarioStatusGet } from "../../services/hooli-services/EndUser/UsuarioStatusGet"
// recoil
import { useRecoilValue, useRecoilState } from 'recoil';
import { userCredentials, rowSelected } from '../../recoilState/GlobalState';

export default function UsuariosNoUsuarios() {
    const [users, setUsers] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [modalCargando, setModalCargando] = useState(true);
    const [procede, setProcede] = useState();
    
    const [row, setRow] = useRecoilState(rowSelected);

    const credentials = useRecoilValue(userCredentials);

    async function retrieveEndUsersNoUsers(
        usuarioNoUsuarioId = null,
        tipoDeDocumentoId = null,
        usuarioNoUsuarioNroDeDocumento = null,
        usuarioNoUsuarioSexo = null,
        usuarioNoUsuarioFechaDeNacimiento = null,
    ) {
        const params = {
            "usuarioNoUsuarioId": usuarioNoUsuarioId,
            "tipoDeDocumentoId": tipoDeDocumentoId,
            "usuarioNoUsuarioNroDeDocumento": usuarioNoUsuarioNroDeDocumento,
            "usuarioNoUsuarioSexo": usuarioNoUsuarioSexo,
            "usuarioNoUsuarioFechaDeNacimiento": usuarioNoUsuarioFechaDeNacimiento
        };

        const payload = { ...credentials, params };
        setCargando(true);
        setRows([]);

        const data = await endUserNoUserGet(payload);
        setUsers(data);
        setCargando(false);
        setRow({ status: false });
    };

    async function retrieveUserStatus() {
        const params = {
            "usuarioUID": row.row ? row.row.id : '',
            "rolCod": "E"
        };

        const payload = { ...credentials, params };
        setModalCargando(true);
        const data = await usuarioStatusGet(payload)
        setModalCargando(false);
        setUserStatus(data);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveEndUsersNoUsers();
    }, [credentials]);

    useEffect(() => {
        users.map((item) => {
            const {
                usuarioNoUsuarioId,
                tipoDeDocumentoId,
                tipoDeDocumentoCod,
                tipoDeDocumentoNombre,
                usuarioNoUsuarioNroDeDocumento,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
                usuarioNoUsuarioDeviceId,
                usuarioNoUsuarioGCMToken,
                usuarioNoUsuarioEquipoMarca,
                usuarioNoUsuarioEquipoModelo,
                aplicacionId,
                aplicacionCod,
                aplicacionNombre,
                usuarioNoUsuarioAppVersion,
                usuarioNoUsuarioTelefonoCelular,
                usuarioNoUsuarioMail,
                usuarioNoUsuarioLatitud,
                usuarioNoUsuarioLongitud,
                usuarioNoUsuarioFechaAlta,
                usuarioNoUsuarioUID,
                usuarioNoUsuarioUFH,
                usuarioNoUsuarioVersion
            } = item;
            
            const users = {
                id: usuarioNoUsuarioId,
                tipoDeDocumentoId,
                tipoDeDocumentoCod,
                tipoDeDocumentoNombre,
                usuarioNoUsuarioNroDeDocumento,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
                usuarioNoUsuarioDeviceId,
                usuarioNoUsuarioGCMToken,
                usuarioNoUsuarioEquipoMarca,
                usuarioNoUsuarioEquipoModelo,
                aplicacionId,
                aplicacionCod,
                aplicacionNombre,
                usuarioNoUsuarioAppVersion,
                usuarioNoUsuarioTelefonoCelular,
                usuarioNoUsuarioMail,
                usuarioNoUsuarioLatitud,
                usuarioNoUsuarioLongitud,
                usuarioNoUsuarioFechaAlta,
                usuarioNoUsuarioUID,
                usuarioNoUsuarioUFH,
                usuarioNoUsuarioVersion,
                [titles[0]]: usuarioNoUsuarioNroDeDocumento ? usuarioNoUsuarioNroDeDocumento : 'N/A',
                [titles[1]]: usuarioNoUsuarioSexo ? usuarioNoUsuarioSexo : 'N/A',
                [titles[2]]: usuarioNoUsuarioFechaDeNacimiento ? usuarioNoUsuarioFechaDeNacimiento : 'N/A',
                [titles[3]]: usuarioNoUsuarioTelefonoCelular ? usuarioNoUsuarioTelefonoCelular : 'N/A',
                [titles[4]]: usuarioNoUsuarioMail ? usuarioNoUsuarioMail : 'N/A'
            };

            setRows(rows => [...rows, users])
            return item
        });
    }, [users]);

    useEffect(() => {
        if (row.row) retrieveUserStatus()
    }, [credentials, row])

    useEffect(() => setRow({ status: false }), []);

    return (
        <main className="container">
            <TransitionModal
                procede={procede}
                setProcede={setProcede}
                userStatus={userStatus}
                cargando={modalCargando}
                updateList={retrieveEndUsersNoUsers}
            />

            <Buttons>                
                {row.status &&
                    <HeaderButton
                        text={"Ver Detalle"}
                        icon={lupa}    
                        customUrl={`/usuarios-no-usuarios/${row.row.id}`}                            
                    />
                }                
                <HeaderButton
                    text="Actualizar pagina"
                    icon={reload}
                    updateList={retrieveEndUsersNoUsers}
                />                
            </Buttons>   

            <div className="row-grid" >
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};