export const Routes = {
    dashboard: {
        route: "/",
        wordKey: "Dashboard",
        description: "Dashboard",
    },
    usuarios: {
        route: "/usuarios",
        wordKey: "Usuarios",
        description: "Home / Usuarios"
    },
    usuariosNoUsuarios: {
        route: "/usuarios-no-usuarios",
        wordKey: "Usuarios No Usuarios",
        description: "Home / Usuarios No Usuarios"
    },
    busquedaDeUsuario: {
        route: "/busquedaDeUsuarios",
        wordKey: "Busqueda de usuarios",
        description: "Home / Busqueda de usuarios"
    },
    hooliBank: {
        route: "/hooliBank",
        wordKey: "Hooli Bank",
        description: "Home / Hooli Bank"
    },
    hooliDriver: {
        route: "/hooliDriver",
        wordKey: "Hooli Driver",
        description: "Home / Hooli Driver"
    },
    preonboardingManual: {
        route: "/preonboardingManual",
        wordKey: "Preonboarding Manual"
    }
};

export const OnBoarding = {
    onboardingEnduser: {
        route: "/onboardingEnduser",
        wordKey: "End User",
        description: "OnBoarding / EndUser"
    },
    onboardingDriver: {
        route: "/onboardingDriver",
        wordKey: "Driver",
        description: "OnBoarding / Driver"
    },
    onboardingMerchant: {
        route: "/onboardingMerchant",
        wordKey: "Merchant",
        description: "OnBoarding / Merchant"
    },
    onboardingAgencia: {
        route: "/onboardingAgencia",
        wordKey: "Agencia",
        description: "OnBoarding / Agencia"
    },
    onboardingWalker: {
        route: "/onboardingWalker",
        wordKey: "Walker",
        description: "OnBoarding / Walker"
    }
};

export const Tablas = {
    aseguradoras: {
        route: "/aseguradoras",
        wordKey: "Compañias aseguradoras",
        description: "Tablas / Compañias aseguradoras"
    },
    localidades: {
        route: "/localidades",
        wordKey: "Localidades",
        description: "Tablas / Localidades"
    },
    condicionesAFIP: {
        route: "/condicionesAFIP",
        wordKey: "Condiciones AFIP",
        description: "Tablas / Condiciones AFIP"
    },
    tiposDeDomicilio: {
        route: "/tiposDeDomicilio",
        wordKey: "Tipos de domicilio",
        description: "Tablas / Tipos de domicilio"
    },
    tiposDeImagen: {
        route: "/tiposDeImagen",
        wordKey: "Tipos de imagen",
        description: "Onboarding / Tipos de imagen"
    },
    formatosDeImagen: {
        route: "/formatosDeImagen",
        wordKey: "Formatos de imagen",
        description: "Onboarding / Formatos de imagen"
    },
    formatosXTipoDeImagen: {
        route: "/formatosXTipoDeImagen",
        wordKey: "Formatos por tipo de imagen",
        description: "Onboarding / Formatos por tipo de imagen"
    },
    monedas: {
        route: "/monedas",
        wordKey: "Monedas",
        description: "Onboarding / Monedas"
    },
    cotizacionesDeMonedas: {
        route: "/cotizacionesDeMonedas",
        wordKey: "Cotizaciones de Monedas",
        description: "Onboarding / Cotizaciones de monedas"
    },
    feriados: {
        route: "/feriados",
        wordKey: "Feriados",
        description: "Onboarding / Feriados"
    },
    seriesDeFeriados: {
        route: "/seriesDeFeriados",
        wordKey: "Series de feriados",
        description: "Onboarding / Series de feriados"
    },
    registros: {
        route: "/registros",
        wordKey: "Registros",
        description: "Tablas / Registros"
    },
    tiposDeRegistro: {
        route: "/tiposDeRegistro",
        wordKey: "Tipos de registro",
        description: "Tablas / Tipos de registro"
    },
    estadosDeRegistro: {
        route: "/estadosDeRegistro",
        wordKey: "Estados de registro",
        description: "Tablas / Estados de registro"
    },
    eventosDeSesion: {
        route: "/eventosDeSesion",
        wordKey: "Eventos de sesion",
        description: "Tablas / Eventos de sesión"
    },
    devices: {
        route: "/devices",
        wordKey: "Devices",
        description: "Tablas / Devices"
    },
    tiposDeDevice: {
        route: "/tiposDeDevice",
        wordKey: "Tipos de device",
        description: "Tablas / Tipos de device"
    },
    zonasDeNoEnroll: {
        route: "/zonasDeNoEnroll",
        wordKey: "Zonas de no enroll",
        description: "Tablas / Zonas de no enroll"
    },
    merchants: {
        route: "/merchants",
        wordKey: "Merchants",
        description: "Tablas / Merchants"
    },
    rubrosDeMerchant: {
        route: "/rubrosDeMerchant",
        wordKey: "Rubros de merchant",
        description: "Tablas / Rubros de merchant"
    },
    cargos: {
        route: "/cargos",
        wordKey: "Cargos",
        description: "Tablas / Cargos - Relacion entre usuarios y merchants"
    }
};