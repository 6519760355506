import axiosPost from '../axiosPost';

export const zonasDeNoEnrollIns = async (arg) => {
    const { nombre, coordenadas } = arg;

    const body = {
        "service": "ZonaNoEnrollIns",
        "params": {
            "zonaNoEnrollNombre": nombre,
            "zonaNoEnrollCoordenadas": coordenadas
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}