import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    codigo: yup.string().uppercase('Debe ser todo mayusculas').required('Ingrese un codigo'),
    bucket: yup.string().required("Ingrese un bucket"),
    folder: yup.string().required("Ingrese un folder"),
});

export const formikValidation = (setNewTipoDeImagen, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : '',
        codigo: row && row[titles[1]] ? row[titles[1]] : '',
        bucket: row && row[titles[2]] ? row[titles[2]] : '',
        folder: row && row[titles[3]] ? row[titles[3]] : '',
    },
    validationSchema,
    onSubmit: (values) => setNewTipoDeImagen(values)
});