import axiosPost from '../axiosPost';

export const tiposDeDeviceUpd = async (arg) => {
    const { id, nombre, codigo, version } = arg;

    const body = {
        "service": "TipoDeviceUpd",
        "params": {
            'tipoDeviceId': id,
            "tipoDeviceNombre": nombre,
            "tipoDeviceCod": codigo,
            "tipoDeviceVersion": version
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}