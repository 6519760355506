import React from 'react';
import TextField from "@material-ui/core/TextField";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// Styles
import { FiltrosStyles } from '../Filtros.styles';

export default function FeriadosSelect(props) {
    const { feriados, feriadoSeleccionado, setFeriadoSeleccionado, handleClick, cargando, updateList } = props;

    const classes = FiltrosStyles();

    return (
        <div className={classes.root}>
            <div className={classes.filtros}>
                <TextField
                    label="Feriado"
                    name="feriado"
                    className={classes.input}
                    value={feriadoSeleccionado}
                    onChange={e => setFeriadoSeleccionado(e.target.value)}
                    disabled={cargando}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: false }}
                >
                    {feriados.map(f => <option key={f.feriadoId} value={f.feriadoId}>{f.feriadoNombre}</option>)}
                </TextField>
            </div>
            <Buttons texto="serie" handleClick={handleClick} updateList={updateList}/>
        </div>
    )
};