import React, { useState } from 'react';
import Foto from '../foto.jpg';
import Datos from './Datos';

function DatosDeRegistro() {
    const [selected, setSelected] = useState(1);
    return (
        <>
            <div className="datos-personales">
                <div className="datos-personales-column">
                    <img className="datos-personales-img" src={Foto} />
                </div>
                <div className="datos-personales-column">
                    <img className="datos-personales-img" src={Foto} />
                </div>
                <div className="datos-personales-column-big">
                    <img className="datos-personales-img-big" src={Foto} />
                </div>
                <div className="datos-personales-column">
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">Match IDX</p>
                        <p className="dato-personal-big">0,347534723</p>
                    </div>
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">MATCH RENAPER</p>
                        <p className="dato-personal-big">0,3</p>
                    </div>
                    <div className="rectangulo-gris">
                        <p className="dato-personal-label">SCORE DE FUENTE EXTERNA</p>
                        <p className="dato-personal-big">0,59</p>
                    </div>
                </div>
            </div>
            <div className="container-pantallas-usuario">
                <div className={selected === 1 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(1)}>Datos de registro</div>
                <div className={selected === 2 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(2)}>Registros adicionales</div>
            </div>
            {selected === 1 && <Datos />}
        </>
    )
};

export default DatosDeRegistro;