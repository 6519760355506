// services
import { cotizacionesDeMonedasIns } from '../../../../services/hooli-services/CotizacionesDeMonedas/CotizacionesDeMonedasIns';
import { cotizacionesDeMonedasDel } from '../../../../services/hooli-services/CotizacionesDeMonedas/CotizacionesDeMonedasDel';
import { cotizacionesDeMonedasUpd } from '../../../../services/hooli-services/CotizacionesDeMonedas/CotizacionesDeMonedasUpd';

export async function postCotizacion(credentials, newCotizacion, setInRequest) {
    if (Object.keys(newCotizacion).length > 0) {
        const payload = {
            ...newCotizacion,
            ...credentials
        };

        setInRequest(true);
        const response = await cotizacionesDeMonedasIns(payload);
        return response
    }
};

export async function deleteCotizacion(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await cotizacionesDeMonedasDel(payload);
    return response
};

export async function updateCotizacion(credentials, newCotizacion, row, setInRequest) {
    if (Object.keys(newCotizacion).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newCotizacion,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await cotizacionesDeMonedasUpd(payload);
        return response
    }
};