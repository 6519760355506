import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup
        .string()
        .max(30, "Debe tener maximo 30 letras")
        .required("Ingrese un nombre"),
    codigo: yup
        .string()
        .max(6, "Debe tener maximo 6 letras")
        .uppercase('Debe ser todo mayusculas')
        .required("Ingrese un codigo"),
    esBase: yup.string().required("Selecciona una opción")
});

export const formikValidation = (setNewMoneda, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        codigo: row && row[titles[1]] ? row[titles[1]] : "",
        esBase: row && row.esBase ? row.esBase : "Y",
    },
    validationSchema,
    onSubmit: (values) => setNewMoneda(values)
});