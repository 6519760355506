import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from "../CommonForm";
import { FormikDateInput, FormikSelectInput, FormikTextInput } from "../../inputs/FormikInputs";
// functions
import { postCotizacion, deleteCotizacion, updateCotizacion } from './CotizacionesDeMonedasFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './CotizacionesDeMonedasFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState'; 

export default function CotizacionesDeMonedasForm(props) {
    const [newCotizacion, setNewCotizacion] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, monedas, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteCotizacion(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Cotizacion eliminada exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postCotizacion(credentials, newCotizacion, setInRequest)
            return handleResponse(response, 'Cotizacion añadida exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateCotizacion(credentials, newCotizacion, selected.row, setInRequest)
            return handleResponse(response, 'Cotizacion modificada exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newCotizacion])

    const formik = formikValidation(setNewCotizacion, selected.row, titles);
    const disabled = inRequest || procede === 'Deleteone';

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name="cantidad"
                formik={formik}
                disabled={disabled}
            />
            <FormikSelectInput
                data={data}
                name="moneda"
                formik={formik}
                disabled={procede === 'Addone' ? inRequest : true}
                options={monedas}
                optionKey="monedaId"
                optionValue="monedaId"
                optionName="monedaNombre"
            />
            <FormikDateInput
                data={data}
                name="fecha"
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name="comprador"
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name="vendedor"
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};