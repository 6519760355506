import React, { useEffect, useRef, useState } from 'react'
import Button from "@material-ui/core/Button";
import classes from './IdentityModal.module.css'
// Form
import { TextField } from '@material-ui/core';
// Servicios
import { endUserIdentityGet } from '../../../services/hooli-services/EndUser/EndUserIdentityGet';
import { endUserIdentityUpd } from '../../../services/hooli-services/EndUser/EndUserIdentityUpd';
// Recoil
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { userCredentials, rowSelected, snackbarData } from '../../../recoilState/GlobalState';

export default function IdentityModal(props) {
    const [comentario, setComentario] = useState("")
    const [userInfo, setUserInfo] = useState();
    const [frenteDNI, setFrenteDNI] = useState(true);
    const [inRequest, setInRequest] = useState(false);
    const fotoDNI = useRef(null);

    const credentials = useRecoilValue(userCredentials);
    const selected = useRecoilValue(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const { setProcede, updateModalList } = props.props

    useEffect(() => {
        const params = { "usuarioId": selected.row.id }

        const payload = { ...credentials, params };
        (async () => {
            const data = await endUserIdentityGet(payload)
            setUserInfo(data)
        })()
    }, [credentials]);

    async function handleClick(codigo) {
        const params = {
            "usuarioId": selected.row.id,
            "estadoDeRegistroCod": codigo,
            "registroXUsuarioComentario": comentario
        };

        const payload = { ...credentials, params };
        setInRequest(true);
        const response = await endUserIdentityUpd(payload);

        if (response) {
            if (response.status.code === 1) {
                setSnackbarInfo({
                    message: 'Identidad confirmada',
                    severity: 'success',
                    open: true
                });
                setInRequest(false);
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                    setProcede(undefined);
                    updateModalList();
                }, 3000)
            }
            else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === 'W' ? 'warning' : 'error',
                    open: true
                });
                setInRequest(false);
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                }, 3000);
            }
        }
    };

    function zoomIn() {
        const currentZoom = fotoDNI.current.style.zoom;
        fotoDNI.current.style.zoom = `${parseInt(currentZoom.split('%')[0]) + 20}%`
    };
    function zoomOut(e) {
        e.preventDefault();
        const currentZoom = fotoDNI.current.style.zoom;
        fotoDNI.current.style.zoom = `${parseInt(currentZoom.split('%')[0]) - 20}%`
    };

    return (
        <div className={classes.container}>
            <div className={classes.column}>
                <img className={classes.selfie} alt="selfie" src={userInfo ? userInfo.imagePathSELFIE : ''} />
                <img className={classes.fotoDNI}
                    ref={fotoDNI}
                    alt="documento"
                    onClick={() => zoomIn()}
                    onContextMenu={(e) => zoomOut(e)}
                    src={userInfo ? userInfo.imagePathDNIFTO : ''}
                />
                <img className={classes.dni}
                    alt="frente/dorso DNI"
                    onClick={() => setFrenteDNI(!frenteDNI)}
                    src={userInfo ? frenteDNI ? userInfo.imagePathDNIFRE : userInfo.imagePathDNIDOR : ''} />
            </div>
            <div className={classes.column}>
                <div>
                    <h2>Confirmar identidad</h2>
                    <p>
                        Valida que ambas fotografias coincidan. El objetivo es asegurar que la persona que ves en la foto del documento es la misma de la selfie.<br />
                        <br />
                        Ten en cuenta que las fotografías pueden ser de la misma persona pero de diferente edad o aspecto físico.<br />
                        <br />
                        Si tienes dudas <b>“Deriva”</b> la consulta a tu supervisor.
                    </p>
                </div>
                <form>
                    <div className={classes.inputRow}>
                        <TextField
                            label='Match IDX'
                            name='idx'
                            placeholder='0'
                            margin="normal"
                            style={{ width: '45%' }}
                            value={userInfo ? userInfo.usuarioDNIMatchScore?.toFixed(2) : ''}
                            disabled
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label='Match Renaper'
                            name='renaper'
                            placeholder='0'
                            margin="normal"
                            style={{ width: '45%' }}
                            value={userInfo ? userInfo.usuarioDNIMatchRENAPER?.toFixed(2) : ''}
                            disabled
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <TextField
                        label='Comentario'
                        name='comentario'
                        placeholder=''
                        margin="normal"
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        multiline={true}
                        fullWidth
                    />
                    <div className={classes.buttonRow}>
                        <Button disabled={inRequest} className={classes.button} onClick={() => handleClick("A")}>
                            Confirmar
                        </Button>
                        <Button disabled={inRequest} className={classes.button}>
                            Derivar
                        </Button>
                    </div>
                    <div className={classes.buttonRow}>
                        <Button disabled={inRequest} className={classes.button} onClick={() => handleClick("R")}>
                            Rechazar
                        </Button>
                        <Button
                            disabled={inRequest} className={`${classes.button} ${classes.closeButton}`}
                            onClick={() => setProcede(undefined)}
                        >
                            <span>Cerrar</span>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}