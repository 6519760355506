// services
import { rubrosDeMerchantIns } from '../../../../services/hooli-services/RubrosDeMerchant/RubrosDeMerchantIns';
import { rubrosDeMerchantDel } from '../../../../services/hooli-services/RubrosDeMerchant/RubrosDeMerchantDel';
import { rubrosDeMerchantUpd } from '../../../../services/hooli-services/RubrosDeMerchant/RubrosDeMerchantUpd';

export async function postAnRubro(credentials, newRubro, newImage, setInRequest) {
    if (Object.keys(newRubro).length > 0) {
        const payload = {
            ...newRubro,
            imagen: newImage,
            ...credentials
        };
        setInRequest(true);
        const response = await rubrosDeMerchantIns(payload);
        return response
    }
};

export async function deleteRubro(credentials, row, setInRequest) {
    const { id } = row;

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await rubrosDeMerchantDel(payload)
    return response
};

export async function updateRubro(credentials, newRubro, row, newImage, src, setInRequest) {
    if (Object.keys(newRubro).length > 0) {
        const { id, version } = row;
        const imagePathRUBROM = newImage ? newImage : src

        const payload = {
            id,
            ...newRubro,
            version,
            imagen: imagePathRUBROM,
            ...credentials
        };

        setInRequest(true);
        const response = await rubrosDeMerchantUpd(payload);
        return response
    }
};