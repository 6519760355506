import React from 'react';
import TextField from "@material-ui/core/TextField";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import { FiltrosStyles } from '../Filtros.styles';

export default function Filtros(props) {
    const { status } = useRecoilValue(rowSelected);

    const { tiposDeRegistro, tipoDeRegistroSeleccionado, setTipoDeRegistroSeleccionado, handleClick, cargando, updateList } = props;
    const classes = FiltrosStyles();

    return (
        <div className={classes.root}>
            <div className={classes.filtros}>
                <TextField
                    label="Tipo de registro"
                    name="tipoDeRegistro"
                    className={classes.input}
                    value={tipoDeRegistroSeleccionado}
                    onChange={e => setTipoDeRegistroSeleccionado(e.target.value)}
                    disabled={cargando}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: false }}
                >
                    <option value="todos">Todos</option>
                    {tiposDeRegistro.map(t => {
                        return <option key={t.tipoRegistroId} value={t.tipoRegistroId}>{t.tipoRegistroNombre}</option>
                    })}
                </TextField>
            </div>
            <Buttons texto="registro" handleClick={handleClick} updateList={updateList} />
        </div>
    )
};