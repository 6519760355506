import React, { useState, useEffect } from "react";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// consts
import { tiposDeImagen as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoTiposDeImagen as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { tiposDeImagenGet } from '../../services/hooli-services/TiposDeImagen/TiposDeImagenGet';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function TiposDeImagen() {
    const [imageTypes, setImageTypes] = useState([]);
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [action, setAction] = useState({});

    const setRow = useSetRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, tiposDeImagenGet, setImageTypes)
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        imageTypes.map((item) => {
            const {
                tipoDeImagenId,
                tipoDeImagenVersion,
                tipoDeImagenNombre,
                tipoDeImagenCod,
                tipoDeImagenBucket,
                tipoDeImagenFolder
            } = item

            const tipoDeImagen = {
                id: tipoDeImagenId,
                version: tipoDeImagenVersion,
                [titles[0]]: tipoDeImagenNombre,
                [titles[1]]: tipoDeImagenCod,
                [titles[2]]: tipoDeImagenBucket,
                [titles[3]]: tipoDeImagenFolder
            }

            setRows(rows => [...rows, tipoDeImagen])
            return item
        });
    }, [imageTypes]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons texto="tipo de imagen" handleClick={handleClick} updateList={updateList} />
            <div className="row-grid" >
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};