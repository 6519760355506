import { TextField } from '@material-ui/core'
import React from 'react'

export function FormikTextInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="text"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    )
};

export function FormikNumberInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="number"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    )
};

export function FormikSelectInput({ data, name, formik, disabled, options, optionKey, optionValue, optionName, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            margin="normal"
            select
            disabled={disabled}
            SelectProps={{ native: true }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        >
            {options.map(option => {
                return <option
                    key={option[optionKey]}
                    value={option[optionValue]}
                >
                    {option[optionName]}
                </option>
            })}
        </TextField>
    )
};

export function FormikYesOrNoInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            margin="normal"
            select
            disabled={disabled}
            SelectProps={{ native: true }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        >
            <option value="Y">Si</option>
            <option value="N">No</option>
        </TextField>
    )
};

export function FormikDateInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="date"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    )
};

export function FormikTimeInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="time"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    )
};