import axiosPost from '../axiosPost';

export const usuarioFaceReg = async (arg) => {
    const { documento, sexo, nacimiento, selfie } = arg

    const body = {
        "service": "UsuarioFaceReg",
        "params": {
            "usuarioIDXId": `001${documento.padStart(8, 0)}${sexo}${nacimiento.split('-').join('')}`,
            "usuarioFotoImg": selfie
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.data
    } catch (err) {
        console.log(err)
    }
}