import React from 'react';
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikSelectInput, FormikDateInput } from '../../components/utils/inputs/FormikInputs';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import { FiltrosStyles } from '../Filtros.styles';
import { useFormik } from 'formik';

export default function FechasSelect(props) {
    const { filtros, setFiltros, monedas, handleClick, cargando, updateList } = props;

    const formik = useFormik({
        initialValues: filtros,
        // validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const classes = FiltrosStyles();
    const data = { moneda: { label: 'Moneda' }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } };

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikSelectInput
                    data={data}
                    name="moneda"
                    formik={formik}
                    disabled={cargando}
                    options={monedas}
                    optionKey="monedaId"
                    optionValue="monedaId"
                    optionName="monedaNombre"
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Buscar" />
                </div>
            </div>
            <Buttons texto="cotizacion" handleClick={handleClick} updateList={updateList} />
        </form>
    )
};