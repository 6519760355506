import axiosPost from '../axiosPost';

export const endUserGet = async (arg) => {
    const { params } = arg

    const body = {
        "service": "EndUserBOListGet",
        "params": params ? params : {}
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
};