import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    codigo: yup.string().length(1, "Debe tener una sola letra").uppercase('Debe ser todo mayusculas').required("Ingrese un codigo"),
});

export const formikValidation = (setNewRegisterType, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        codigo: row && row[titles[1]] ? row[titles[1]] : "",
    },
    validationSchema,
    onSubmit: (values) => setNewRegisterType(values)
});