// services
import { formatosDeImagenIns } from '../../../../services/hooli-services/FormatosDeImagen/FormatosDeImagenIns';
import { formatosDeImagenDel } from '../../../../services/hooli-services/FormatosDeImagen/FormatosDeImagenDel';
import { formatosDeImagenUpd } from '../../../../services/hooli-services/FormatosDeImagen/FormatosDeImagenUpd';

export async function postFormato(credentials, newFormato, setInRequest) {
    if (Object.keys(newFormato).length > 0) {
        const payload = { ...newFormato, ...credentials };

        setInRequest(true);
        const response = await formatosDeImagenIns(payload);
        return response
    }
};

export async function deleteFormato(credentials, row, setInRequest) {
    const { id } = row
    const payload = { id, ...credentials }

    setInRequest(true);
    const response = await formatosDeImagenDel(payload);
    return response
};

export async function updateFormato(credentials, newFormato, row, setInRequest) {
    if (Object.keys(newFormato).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newFormato,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await formatosDeImagenUpd(payload)
        return response
    }
};