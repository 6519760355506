import React, { useState } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import classes from './LocationModal.module.css';
import SearchBox from './SearchBox';
import FormButton from '../../../components/utils/button/FormButton';

const containerStyle = {
    width: '100%',
    height: '300px',
    marginBottom: '1rem'
};
const libraries = ["places"]
export default function MapaModal({ props }) {
    const { lat, lng, userInfo, setUserInfo, setSecondaryOpen } = props;
    const [coordenadas, setCoordenadas] = useState({
        lat: lat ? typeof lat === "number" ? lat : parseFloat(lat) : -34.6036844,
        lng: lng ? typeof lng === "number" ? lng : parseFloat(lng) : -58.3815591
    });

    const handleClick = () => {
        setUserInfo({
            ...userInfo,
            domicilioLatitud: coordenadas.lat,
            domicilioLongitud: coordenadas.lng
        });
        setSecondaryOpen(false);
    };

    const data = {
        title: 'Seleccionar coordenadas',
        button: 'Seleccionar coordenadas'
    };

    return (
        <div className={classes.mapaModal}>
            <LoadScript
                id="second-google-map-script"
                googleMapsApiKey="AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc"
                libraries={libraries}
            >
                <SearchBox coordenadas={coordenadas} setCoordenadas={setCoordenadas} />
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordenadas}
                    zoom={14}
                    onClick={(e) => setCoordenadas({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
                >
                    <Marker position={coordenadas} />
                </GoogleMap>
                <FormButton setSecondaryOpen={setSecondaryOpen} handleAction={handleClick} newFormData={data} />
            </LoadScript>
        </div>
    )
}