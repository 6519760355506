import axiosPost from '../axiosPost';

export const serieDeFeriadosIns = async (arg) => {
    const { fecha, comercial, bancario, feriadoSeleccionado } = arg;

    const body = {
        "service": "FeriadoSerieIns",
        "params": { 
            "feriadoId": feriadoSeleccionado,
            "feriadoSerieFecha": fecha,
            "feriadoSerieEsComercial": comercial,
            "feriadoSerieEsBancario": bancario
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}