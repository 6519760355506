import axiosPost from '../axiosPost';

export const tiposDeImagenUpd = async (arg) => {
    const { id, nombre, codigo, bucket, folder, version } = arg;

    const body = {
        "service": "TipoDeImagenUpd",
        "params": {
            "tipoDeImagenId": id,
            "tipoDeImagenNombre": nombre,
            "tipoDeImagenCod": codigo,
            "tipoDeImagenBucket": bucket,
            "tipoDeImagenFolder": folder,
            "tipoDeImagenVersion": version,
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
};