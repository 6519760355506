import React, { useRef, useState, useEffect } from 'react';
import classes from './PreonboardingManual.module.css';
import Compress from 'compress.js'
import rotar from '../../assets/icons/rotate.png';
// Components
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import TransitionModal from '../../components/utils/modals/Modals';
// Services
import { renaperPreonboardCheck } from '../../services/hooli-services/RENAPER/RENAPERPreonboardCheck';
// Const
import { modalsInfoPreonboardingManual } from '../../consts/Modals-info';
// Recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { formData, formIsOpen, snackbarData, userCredentials } from '../../recoilState/GlobalState';

export default function PreonboardingManual() {
    const [images, setImages] = useState([]);
    const [recorte, setRecorte] = useState();
    const [documento, setDocumento] = useState('');
    const [sexo, setSexo] = useState('M');
    const [procede, setProcede] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const selfieRef = useRef(null);
    const frenteRef = useRef(null);
    const dorsoRef = useRef(null);
    const recorteRef = useRef(null);

    const credentials = useRecoilValue(userCredentials);
    const [isOpen, setIsOpen] = useRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setFormInfo = useSetRecoilState(formData);

    const readerHandler = async (index, file) => {
        const compress = new Compress();

        const newImages = [...images];
        const newImage = await compress.compress([file], { quality: 600000 / file.size });

        newImages[index] = newImage[0].data;
        return setImages(newImages);
    };

    const handleImg = (e, index) => {
        let file = e.target.files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => await readerHandler(index, file);
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInRequest(true);

        const compress = new Compress();
        const file = Compress.convertBase64ToFile(recorte ? recorte.split('data:image/png;base64,')[1] : '');
        const newRecorte = await compress.compress([file], { quality: 0.6 });

        const params = {
            ...credentials,
            documento,
            sexo,
            recorte: newRecorte[0].data
        }
        const response = await renaperPreonboardCheck(params);
        setInRequest(false);
        if (response.status.code === 0) {
            setSnackbarInfo({
                message: response.status.errmsg,
                severity: response.status.action === 'W' ? 'warning' : 'error',
                open: true
            });
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
        }
        else {
            setUserInfo(response.result[0]);
            setFormInfo(modalsInfoPreonboardingManual);
            setProcede("usuarioIns");
            setIsOpen(true);
        }
    };

    const handleRotate = (ref, secondRef) => {
        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;

        let transform;
        switch (ref.current.style.transform) {
            case '': transform = "rotate(90deg)"
                break;
            case 'rotate(90deg)': transform = "rotate(180deg)"
                break;
            case 'rotate(180deg)': transform = "rotate(270deg)"
                break;
            case 'rotate(270deg)': transform = ""
                break;
            default: transform = ""
        };

        if (secondRef && secondRef.current) secondRef.current.style.transform = transform;
        ref.current.style.transform = transform
        ref.current.style.width = height;
        ref.current.style.height = width;
    }

    useEffect(() => {
        if (!isOpen && procede !== 'recorte') {
            setImages([]);
            setRecorte('');
            setDocumento('');
            document.getElementById('selfie').value = '';
            document.getElementById('frenteDNI').value = '';
            document.getElementById('dorsoDNI').value = '';
        };
        if (!isOpen && procede === 'recorte' && recorteRef.current) recorteRef.current.style.transform = frenteRef.current.style.transform;
    }, [isOpen]);

    return (
        <main className="container">
            <TransitionModal
                selfie={images[0]}
                frenteDNI={images[1]}
                dorsoDNI={images[2]}
                setImages={setImages}
                selfieRef={selfieRef}
                frenteRef={frenteRef}
                dorsoRef={dorsoRef}
                recorteRef={recorteRef}
                recorte={recorte}
                setRecorte={setRecorte}
                procede={procede}
                userInfo={userInfo}
                sexo={sexo}
                documento={documento}
            />
            <div className={classes.container}>
                <div className={classes.column}>
                    <div className={classes.imageRow}>
                        <div className={classes.imageContainer}>
                            {images[0] ?
                                <>
                                    <img
                                        src={`data:image/png;base64,${images[0]}`}
                                        alt="Selfie"
                                        ref={selfieRef}
                                        className={classes.selfie}
                                        onClick={() => document.getElementById('selfie').click()}
                                    />
                                    <img src={rotar} className={classes.rotar} onClick={(e) => handleRotate(selfieRef)} />
                                </>
                                :
                                <p className={classes.selfie} onClick={() => document.getElementById('selfie').click()}>Subir selfie</p>
                            }
                            <input
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, image/jpg"
                                id="selfie"
                                onChange={(e) => handleImg(e, 0)}
                                type="file"
                            />
                        </div>
                        <div className={classes.imageContainer}>
                            {images[1] ?
                                <>
                                    <img
                                        src={`data:image/png;base64,${images[1]}`}
                                        alt="Frente DNI"
                                        ref={frenteRef}
                                        className={classes.dni}
                                        onClick={() => document.getElementById('frenteDNI').click()}
                                    />
                                    <img src={rotar} className={classes.rotar} onClick={() => handleRotate(frenteRef, recorteRef)} />
                                </>
                                :
                                <p className={classes.dni} onClick={() => document.getElementById('frenteDNI').click()}>Subir frente del DNI</p>
                            }
                            <input
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, image/jpg"
                                id="frenteDNI"
                                onChange={(e) => handleImg(e, 1)}
                                type="file"
                            />
                        </div>
                    </div>
                    <div className={classes.imageRow}>
                        <div className={classes.imageContainer}>
                            {images[2] ?
                                <>
                                    <img
                                        src={`data:image/png;base64,${images[2]}`}
                                        alt="Dorso DNI"
                                        ref={dorsoRef}
                                        className={classes.dorsoDNI}
                                        onClick={() => document.getElementById('dorsoDNI').click()}
                                    />
                                    <img src={rotar} className={classes.rotar} onClick={() => handleRotate(dorsoRef)} />
                                </>
                                :
                                <p className={classes.dorsoDNI} onClick={() => document.getElementById('dorsoDNI').click()}>Subir dorso del DNI</p>
                            }
                            <input
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, image/jpg"
                                id="dorsoDNI"
                                onChange={(e) => handleImg(e, 2)}
                                type="file"
                            />
                        </div>
                        {recorte &&
                            <div className={classes.imageContainer}>
                                <img
                                    src={recorte}
                                    alt="Foto DNI"
                                    ref={recorteRef}
                                    className={classes.recorte}
                                    onClick={() => {
                                        setIsOpen(true);
                                        setProcede("recorte")
                                    }}
                                />
                            </div>
                        }
                        {images[1] && !recorte &&
                            <div className={classes.imageContainer}>
                                <p
                                    className={classes.recorte}
                                    onClick={() => {
                                        setIsOpen(true);
                                        setProcede("recorte")
                                    }}>
                                    Recortar imagen del DNI
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div className={classes.column}>
                    <div>
                        <h2 className={classes.title}>Chequear datos en RENAPER</h2>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <TextField
                            label='Documento'
                            name='documento'
                            placeholder='Ingrese documento'
                            margin="normal"
                            value={documento}
                            onChange={(e) => setDocumento(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                        <FormControl>
                            <FormLabel style={{ fontSize: '0.75rem' }} >Sexo</FormLabel>
                            <RadioGroup row value={sexo} onChange={(e) => setSexo(e.target.value)}>
                                <FormControlLabel value="F" control={<Radio />} label="Femenino" />
                                <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                            </RadioGroup>
                        </FormControl>
                        <div className={classes.buttonRow}>
                            <Button type="submit" className={classes.button}>
                                Chequear datos
                            </Button>
                        </div>
                    </form>
                    {inRequest && <p>Chequeando datos...</p>}
                </div>
            </div>
        </main>
    );
}