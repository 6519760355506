// services
import { tiposDeDeviceIns } from '../../../../services/hooli-services/TiposDeDevice/TiposDeDeviceIns';
import { tiposDeDeviceDel } from '../../../../services/hooli-services/TiposDeDevice/TiposDeDeviceDel';
import { tiposDeDeviceUpd } from '../../../../services/hooli-services/TiposDeDevice/TiposDeDeviceUpd';

export async function postTipoDeDevice(credentials, newTipoDeDevice, setInRequest) {
    if (Object.keys(newTipoDeDevice).length > 0) {
        const payload = { ...newTipoDeDevice, ...credentials };

        setInRequest(true);
        const response = await tiposDeDeviceIns(payload);
        return response
    }
};

export async function deleteTipoDeDevice(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await tiposDeDeviceDel(payload);
    return response
};

export async function updateTipoDeDevice(credentials, newTipoDeDevice, row, setInRequest) {
    if (Object.keys(newTipoDeDevice).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newTipoDeDevice,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await tiposDeDeviceUpd(payload);
        return response
    }
};