import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from "../CommonForm";
import { FormikDateInput, FormikYesOrNoInput } from "../../inputs/FormikInputs";
// functions
import { postSerie, deleteSerie, updateSerie } from './SerieDeFeriadosFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './SerieDeFeriadosFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function SerieDeFeriadosForm(props) {
    const [newSerie, setNewSerie] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede, feriadoSeleccionado } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteSerie(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Serie de feriados eliminada exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postSerie(credentials, newSerie, feriadoSeleccionado, setInRequest)
            return handleResponse(response, 'Serie de feriados añadida exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateSerie(credentials, newSerie, selected.row, setInRequest)
            return handleResponse(response, 'Serie de feriados modificada exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newSerie])

    const formik = formikValidation(setNewSerie, selected.row, titles);
    const disabled = inRequest || procede === 'Deleteone';

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikDateInput
                data={data}
                name="fecha"
                formik={formik}
                disabled={disabled}
            />
            <FormikYesOrNoInput
                data={data}
                name="comercial"
                formik={formik}
                disabled={disabled}
            />
            <FormikYesOrNoInput
                data={data}
                name="bancario"
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};