import React, { useState, useEffect } from "react";
// components
import Filtros from "./Filtros";
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// consts
import { merchant as titles } from "../../consts/titulos-de-tablas";
//Services 
import { merchantsGet } from '../../services/hooli-services/Merchants/MerchantsGet';
import { transformDNI } from "../../services/Local-services";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected } from '../../recoilState/GlobalState';

export default function Merchant() {
    const [merchants, setMerchants] = useState([]);
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [filtros, setFiltros] = useState({ nombre: '', cuit: '', documento: '' });
    const setRow = useSetRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    async function retrieveMerchants() {
        const params = {
            "merchantCUIT": filtros.cuit === '' ? null : filtros.cuit,
            "usuarioNroDeDocumento": filtros.documento === '' ? null : filtros.documento.replace(/\./g, ''),
            "merchantNombre": filtros.nombre === '' ? null : filtros.nombre
        };

        const payload = { ...credentials, params }
        setRows([]);
        setCargando(true);

        const data = await merchantsGet(payload);

        setMerchants(data);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveMerchants();
    }, [credentials, filtros]);

    useEffect(() => {
        if (merchants.length > 0) {
            merchants.map((item) => {
                const {
                    merchantId,
                    merchantNombre,
                    merchantRZ,
                    merchantCUIT,
                    merchantPFPJLeyenda,
                    rubroMerchantNombre,
                    merchantFechaAlta,
                    planNombre,
                    usuarioApellidos,
                    usuarioNombres,
                    usuarioNroDeDocumento
                } = item;

                const merchant = {
                    id: merchantId,
                    ...item,
                    [titles[0]]: merchantNombre,
                    [titles[1]]: merchantRZ,
                    [titles[2]]: merchantCUIT,
                    [titles[3]]: merchantPFPJLeyenda,
                    [titles[4]]: merchantFechaAlta,
                    [titles[5]]: rubroMerchantNombre,
                    [titles[6]]: planNombre,
                    [titles[7]]: usuarioApellidos,
                    [titles[8]]: usuarioNombres,
                    [titles[9]]: usuarioNroDeDocumento ? transformDNI(usuarioNroDeDocumento) : '',
                };

                setRows(rows => [...rows, merchant])
                return item
            })
        }
    }, [merchants]);

    useEffect(() => setRow({ status: false }), []);

    return (
        <main className="container">
            <TransitionModal procede="infoMerchant" />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                updateList={retrieveMerchants}
            />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};