// services
import { cargosIns } from '../../../../services/hooli-services/Cargos/CargosIns';
import { cargosDel } from '../../../../services/hooli-services/Cargos/CargosDel';
import { cargosUpd } from '../../../../services/hooli-services/Cargos/CargosUpd';

export async function postCargo(credentials, newCargo, setInRequest) {
    if (Object.keys(newCargo).length > 0) {
        const payload = {
            ...newCargo,
            ...credentials
        };

        setInRequest(true);
        const response = await cargosIns(payload);
        return response
    }
};

export async function deleteCargo(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await cargosDel(payload);
    return response
};

export async function updateCargo(credentials, newCargo, row, setInRequest) {
    if (Object.keys(newCargo).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newCargo,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await cargosUpd(payload);
        return response
    }
};