import axiosPost from '../axiosPost';

export const serieDeFeriadosUpd = async (arg) => {
    const { id, fecha, comercial, bancario, version } = arg;

    const body = {
        "service": "FeriadoSerieUpd",
        "params": {
            'feriadoSerieId': id,
            "feriadoSerieFecha": fecha,
            "feriadoSerieEsComercial": comercial,
            "feriadoSerieEsBancario": bancario,
            "feriadoSerieVersion": version,
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}