import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { usuarios as titles } from "../../consts/titulos-de-tablas";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { endUserGet } from '../../services/hooli-services/EndUser/EndUserGet';
import { registrosGet } from "../../services/hooli-services/Registros/RegistrosGet";
import { usuarioStatusGet } from "../../services/hooli-services/EndUser/UsuarioStatusGet"
// recoil
import { useRecoilValue, useRecoilState } from 'recoil';
import { userCredentials, rowSelected } from '../../recoilState/GlobalState';

export default function Usuarios() {
    const [users, setUsers] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [modalCargando, setModalCargando] = useState(true);
    const [procede, setProcede] = useState();
    const [filtros, setFiltros] = useState({ apellido: '', documento: '' });

    const [row, setRow] = useRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);

    async function retrieveEndUsers() {
        const params = {
            "usuarioApellidos": filtros.apellido,
            "usuarioNroDeDocumento": filtros.documento.replace(/\./g, ''),
        };

        const payload = { ...credentials, params };
        setCargando(true);
        setRows([]);

        const data = await endUserGet(payload);
        setUsers(data);
        setCargando(false);
        setRow({ status: false });
    };

    async function retrieveUserStatus() {
        const params = {
            "usuarioUID": row.row ? row.row.id : '',
            "rolCod": "E"
        };

        const payload = { ...credentials, params };
        setModalCargando(true);
        const data = await usuarioStatusGet(payload)
        setModalCargando(false);
        setUserStatus(data);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveEndUsers()
    }, [credentials, filtros]);

    useEffect(() => {
        users.map((item) => {
            const {
                usuarioId,
                usuarioApellidos,
                usuarioNombres,
                tipoDeDocumentoCod,
                usuarioNroDeDocumento,
                usuarioSexo,
                usuarioStatusRegistro,
                usuarioFechaAlta,
                cuentaOK,
                cuentaBO,
                cuentaRE
            } = item;

            const users = {
                id: usuarioId,
                aprobados: cuentaOK,
                enEspera: cuentaBO,
                rechazados: cuentaRE,
                [titles[0]]: usuarioApellidos,
                [titles[1]]: usuarioNombres,
                [titles[2]]: tipoDeDocumentoCod,
                [titles[3]]: usuarioNroDeDocumento.toLocaleString(),
                [titles[4]]: usuarioSexo,
                [titles[5]]: usuarioFechaAlta.substring(0, 10),
                [titles[6]]: usuarioStatusRegistro,
            };

            setRows(rows => [...rows, users])
            return item
        });
    }, [users]);

    useEffect(() => {
        if (row.row) retrieveUserStatus()
    }, [credentials, row])

    useEffect(() => setRow({ status: false }), []);

    return (
        <main className="container">
            <TransitionModal
                procede={procede}
                setProcede={setProcede}
                userStatus={userStatus}
                cargando={modalCargando}
                updateList={retrieveEndUsers}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                updateList={retrieveEndUsers}
                id={row.row ? row.row.id : ''}
                cargando={cargando}
            />
            <div className="row-grid" >
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};