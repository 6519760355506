import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    coordenadas: yup.string().required("")
});

export const formikValidation = (setNewZone, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        coordenadas: row && row.coordenadas ? row.coordenadas : ""
    },
    validationSchema,
    onSubmit: (values) => setNewZone(values)
});