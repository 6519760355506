import axiosPost from '../axiosPost';

export const endUserIdentityUpd = async (arg) => {
    const name = 'End User Identity Update';
    let result;

    const { params } = arg

    const body = {
        "service": "EndUserOnboardIdentidadBOUpd",
        "params": params ? params : {}
    }
    console.log(params)
    try {
        result = await axiosPost('Y', body, arg)
    } catch (err) {
        console.log(err)
    }
    console.log(result.data)
    if (!result || result === null) {
        console.log(`Error getting ${name}`)
    }

    return result.data
}