import React, { useState } from 'react';
import DomicilioPersonal from './DomicilioPersonal';
import Foto from '../foto.jpg';

function DatosPersonales() {
    const [selected, setSelected] = useState(1);
    return (
        <>
            <div className="datos-personales">
                <div className="datos-personales-column">
                    <img className="datos-personales-img" src={Foto}/>
                </div>
                <div className="datos-personales-column">
                    <p className="dato-personal-label">Tipo de documento</p>
                    <p className="dato-personal">DNI</p>
                    <p className="dato-personal-label">Numero de documento</p>
                    <p className="dato-personal">38125628</p>
                    <p className="dato-personal-label">Fecha de nacimiento</p>
                    <p className="dato-personal">26/04/1994</p>
                    <p className="dato-personal-label">Sexo</p>
                    <p className="dato-personal">Femenino</p>
                </div>
                <div className="datos-personales-column">
                    <p className="dato-personal-label">E-Mail</p>
                    <p className="dato-personal">lorenaipsum@gmail.com</p>
                    <p className="dato-personal-label">Celular</p>
                    <p className="dato-personal">(351) 635544</p>
                    <p className="dato-personal-label">CUIT</p>
                    <p className="dato-personal">27-38125628-1</p>
                    <p className="dato-personal-label">NDI</p>
                    <p className="dato-personal">12</p>
                </div>
                <div className="datos-personales-column">
                    <p className="titulo-violeta">Factores activos</p>
                    <p className="dato-personal">Huella</p>
                </div>
                <div className="datos-personales-column">
                    <div className="rectangulo-gris">
                        <p className="titulo-violeta">Ultimo login</p>
                        <p className="dato-personal">24/10/2020 15:55</p>
                    </div>
                    <div className="rectangulo-gris">
                        <p className="titulo-violeta">Frecuencia de login</p>
                        <p className="dato-personal">1 login en la ultima semana</p>
                    </div>
                </div>
            </div>
            <div className="container-pantallas-usuario">
                <div className={selected === 1 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(1)}>Domicilio personal</div>
                <div className={selected === 2 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(2)}>Domicilio laboral</div>
                <div className={selected === 3 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(3)}>Otros</div>
            </div>
            {selected === 1 && <DomicilioPersonal />}
        </>
    )
}

export default DatosPersonales;