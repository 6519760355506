// services
import { merchantsIns } from '../../../../services/hooli-services/Merchants/MerchantsIns';
import { merchantsDel } from '../../../../services/hooli-services/Merchants/MerchantsDel';
import { merchantsUpd } from '../../../../services/hooli-services/Merchants/MerchantsUpd';

export async function postMerchant(credentials, newMerchant, setInRequest) {
    if (Object.keys(newMerchant).length > 0) {
        const payload = { ...newMerchant, ...credentials };

        setInRequest(true);
        const response = await merchantsIns(payload);
        return response
    }
};

export async function deleteMerchant(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await merchantsDel(payload);
    return response
};

export async function updateMerchant(credentials, newMerchant, row, setInRequest) {
    if (Object.keys(newMerchant).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newMerchant,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await merchantsUpd(payload);
        return response
    }
};