import axiosPost from './axiosPost';

export const usuarioMinimosGet = async (arg) => {
    const name = 'Usuario minimos';

    let result;

    const body = {
        "service": "UsuarioMinimosGet",
        "params": {}
    }

    try {
        result = await axiosPost('Y', body, arg)
    } catch (err) {
        console.log(err)
    }

    if (!result || result === null) {
        console.log(`Error getting ${name}`)
    }

    return result.data.result[0]
}

export default usuarioMinimosGet;