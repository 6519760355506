import React, { useRef, useState, useEffect } from 'react';
import Compress from 'compress.js'
import rotar from '../../assets/icons/rotate.png';
import { useLocation } from 'react-router-dom';

// Components
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import TransitionModal from '../../components/utils/modals/Modals';
import CircularIndeterminate from '../../components/utils/spinner/Spinner';

// Services
import { renaperPreonboardCheck } from '../../services/hooli-services/RENAPER/RENAPERPreonboardCheck';
import endUserNoUserByIdGet from '../../services/hooli-services/EndUser/EndUserNoUserByIdGet';
// Const
import { modalsInfoPreonboardingManual } from '../../consts/Modals-info';

// Recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { formData, formIsOpen, snackbarData, userCredentials } from '../../recoilState/GlobalState';

import classes from './PreonboardingManual.module.css';

export default function UsuarioNoUsuarioManualPreOnBoarding() {
    const {pathname} = useLocation();
    
    const [images, setImages] = useState({selfie: null, dniFrente: null, dniDorso: null, recorte: null});
    const [recorte, setRecorte] = useState();
    const [documento, setDocumento] = useState('');
    const [sexo, setSexo] = useState('M');
    const [procede, setProcede] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [inRequest, setInRequest] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [errors, setErrors] = useState({})

    const selfieRef = useRef(null);
    const frenteRef = useRef(null);
    const dorsoRef = useRef(null);
    const recorteRef = useRef(null);

    const credentials = useRecoilValue(userCredentials);

    const [isOpen, setIsOpen] = useRecoilState(formIsOpen);
    
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setFormInfo = useSetRecoilState(formData);

    const readerHandler = async (index, file) => {
        const compress = new Compress();

        const newImages = [...images];
        const newImage = await compress.compress([file], { quality: 600000 / file.size });

        newImages[index] = newImage[0].data;
        return setImages(newImages);
    };

    const handleImg = (e, index) => {
        let file = e.target.files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => await readerHandler(index, file);
            reader.readAsBinaryString(file);
        }
    };

    const handleErrorCheck = () => {
        // images existence check        
        for(const img in images){
            if(!images[img]) {
                console.log('errors', img)
                setErrors(errors => ({...errors, [img]: `Debe agregar imagen ${img}`}));
                setCargando(false);
                setInRequest(false);
            }else{
                setErrors(errors => ({...errors, [img]: null}))
            }
        }
        
        // document check
        if(!documento) {
            setErrors(errors => ({...errors, 'documento': `Debe agregar un documento`}));
        } else { 
            setErrors(errors => ({...errors, 'documento': null}))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setInRequest(inRequest => !inRequest);

        handleErrorCheck();

        if(!Object.keys(errors).length) {
            return;
        }

        const compress = new Compress();
        const file = Compress.convertBase64ToFile(images.recorte ? images.recorte.split('data:image/png;base64,')[1] : (recorte ? recorte.split('data:image/png;base64,')[1] : ''));
        const newRecorte = await compress.compress([file], { quality: 0.6 });

        const params = {
            ...credentials,
            documento,
            sexo,
            recorte: newRecorte[0].data
        }

        const response = await renaperPreonboardCheck(params);
        setInRequest(inRequest => !inRequest);
        
        if (response.status.code === 0) {

            setSnackbarInfo({
                message: response.status.errmsg,
                severity: response.status.action === 'W' ? 'warning' : 'error',
                open: true
            });
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
            
        } else {
            setUserInfo(response.result[0]);
            setFormInfo(modalsInfoPreonboardingManual);
            setProcede("usuarioIns");
            setIsOpen(true);
        };
    };

    const handleRotate = (ref, secondRef) => {
        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;

        let transform;
        switch (ref.current.style.transform) {
            case '': transform = "rotate(90deg)"
                break;
            case 'rotate(90deg)': transform = "rotate(180deg)"
                break;
            case 'rotate(180deg)': transform = "rotate(270deg)"
                break;
            case 'rotate(270deg)': transform = ""
                break;
            default: transform = ""
        };

        if (secondRef && secondRef.current) secondRef.current.style.transform = transform;
        ref.current.style.transform = transform
        ref.current.style.width = height;
        ref.current.style.height = width;
    }

    async function downloadIMG(imgUrls){
        /* Loading images from a remote server and converting them to data URLs. */        
        for(const imgUrl in imgUrls){
            if(!imgUrl) return;

            const image = new Image();
            
            image.crossOrigin='anonymous';

            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.height = image.naturalHeight;
                canvas.width = image.naturalWidth;
                
                ctx.drawImage(image, 0, 0);
                
                const dataUrl = canvas.toDataURL();

                setImages(images => ({...images, [imgUrl]: dataUrl}));
            }
            
            image.src = imgUrls[imgUrl];        
        }
    }

    async function retrieveEndUsersNoUser( usuarioNoUsuarioId ) {
        const params = {
            "usuarioNoUsuarioId": usuarioNoUsuarioId
        };

        const payload = { ...credentials, params };
        setCargando(cargando => !cargando);        

        const data = await endUserNoUserByIdGet(payload);

        downloadIMG({selfie: data[0].imagePathSELFIE, dniFrente: data[0].imagePathDNIFRE, dniDorso: data[0].imagePathDNIDOR, recorte: data[0].imagePathDNIFTO});
        
        setDocumento(String(data[0].usuarioNoUsuarioNroDeDocumento));
        setSexo(data[0].usuarioNoUsuarioSexo);

        setCargando(cargando => !cargando);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveEndUsersNoUser(pathname.split("/")[2]);
    }, [credentials]);

    useEffect(() => {
        if (!isOpen && procede !== 'recorte' && !cargando) {
            setRecorte('');
            setDocumento('');
            document.getElementById('selfie').value = '';
            document.getElementById('frenteDNI').value = '';
            document.getElementById('dorsoDNI').value = '';
        };
        if (!isOpen && procede === 'recorte' && recorteRef.current) recorteRef.current.style.transform = frenteRef.current.style.transform;
    }, [isOpen]);

    return (    
        cargando && images
        ?
            <CircularIndeterminate />
        :               
            <main className="container">
                <TransitionModal
                    selfie={images.selfie}
                    frenteDNI={images.dniFrente}
                    dorsoDNI={images.dniDorso}
                    setImages={setImages}
                    selfieRef={selfieRef}
                    frenteRef={frenteRef}
                    dorsoRef={dorsoRef}
                    recorteRef={recorteRef}
                    recorte={images.recorte ? images.recorte : recorte}
                    setRecorte={setRecorte}
                    images={images}
                    procede={procede}
                    userInfo={userInfo}
                    sexo={sexo}
                    documento={documento}
                    pureBase64
                />

                <div className={classes.container}>
                    <div className={classes.column}>

                        <div className={classes.imageRow}>

                            <div className={classes.imageContainer}>
                                {images.selfie ?
                                    <span className={classes.imageRotate}>                                    
                                        <img
                                            src={images.selfie}
                                            alt="Selfie"
                                            ref={selfieRef}
                                            className={classes.selfie}
                                            onClick={() => document.getElementById('selfie').click()}
                                            onChange={handleErrorCheck}
                                        />
                                        <img src={rotar} className={classes.rotar} onClick={() => handleRotate(selfieRef)} alt='selfie'/>
                                    </span>
                                    :
                                    <p role='button' className={classes.selfie} onClick={() => document.getElementById('selfie').click()}>Subir selfie</p>
                                }
                                <input
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, image/jpg"
                                    id="selfie"
                                    onChange={(e) => handleImg(e, 0)}
                                    type="file"
                                />
                            </div>
                                
                            <div className={classes.imageContainer}>
                                {images.dniFrente ?
                                    <span className={classes.imageRotate}>                                    
                                        <img
                                            src={images.dniFrente}
                                            alt="Frente DNI"
                                            ref={frenteRef}
                                            className={classes.dni}
                                            onClick={() => document.getElementById('frenteDNI').click()}
                                            onChange={handleErrorCheck}
                                        />
                                        <img alt='rotate' src={rotar} className={classes.rotar} onClick={() => handleRotate(frenteRef, recorteRef)} />
                                    </span>
                                    :
                                    <p role='button' className={classes.dni} onClick={() => document.getElementById('frenteDNI').click()}>Subir frente del DNI</p>
                                }
                                <input
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, image/jpg"
                                    id="frenteDNI"
                                    onChange={(e) => handleImg(e, 1)}
                                    type="file"
                                />
                            </div>

                        </div>

                        <div className={classes.imageRow}>
                            <div className={classes.imageContainer}>
                                {images.dniDorso ?
                                    <span className={classes.imageRotate}>                                    
                                        <img
                                            src={images.dniDorso}
                                            alt="Dorso DNI"
                                            ref={dorsoRef}
                                            className={classes.dorsoDNI}
                                            onClick={() => document.getElementById('dorsoDNI').click()}
                                            onChange={handleErrorCheck}
                                        />
                                        <img alt='dorso dni' src={rotar} className={classes.rotar} onClick={() => handleRotate(dorsoRef)} />
                                    </span>
                                    :
                                    <p className={classes.dorsoDNI} onClick={() => document.getElementById('dorsoDNI').click()}>Subir dorso del DNI</p>
                                }
                                <input
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, image/jpg"
                                    id="dorsoDNI"
                                    onChange={(e) => handleImg(e, 2)}
                                    type="file"
                                />
                            </div>

                            {(images.recorte || recorte) &&
                                <div className={classes.imageContainer}>
                                    <img
                                        src={images.recorte ? images.recorte : recorte}
                                        alt="Foto DNI"
                                        ref={recorteRef}
                                        className={classes.recorte}
                                        onClick={() => {
                                            setIsOpen(true);
                                            setProcede("recorte")
                                        }}
                                        onChange={handleErrorCheck}
                                    />
                                </div>
                            }

                            {
                                (images.dniFrente && !images.recorte && !recorte) 
                                    &&
                                <div className={classes.imageContainer}>
                                    <p
                                        className={classes.recorte}
                                        onClick={() => {
                                            setIsOpen(true);
                                            setProcede("recorte")
                                        }}                                        
                                        >
                                        Recortar imagen del DNI
                                    </p>
                                </div>
                            }
                        </div>

                    </div>

                    <div className={classes.columnRenaper}>

                        <h2 className={classes.title}>Chequear datos en RENAPER</h2>                        

                        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
                            <div className={classes.inputDiv}> 
                                <TextField
                                    className={classes.input}
                                    label='Documento'
                                    name='documento'
                                    placeholder='Ingrese documento'
                                    margin="normal"
                                    value={documento}
                                    onChange={(e) => setDocumento(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <FormControl className={classes.formControl}>
                                <FormLabel style={{ fontSize: '0.9rem' }}>Sexo</FormLabel>
                                <RadioGroup row className={classes.radio} value={sexo} onChange={(e) => setSexo(e.target.value)}>
                                    <FormControlLabel value="X" control={<Radio />} label="No Binario" />
                                    <FormControlLabel value="F" control={<Radio />} label="Femenino" />
                                    <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                                </RadioGroup>
                            </FormControl>

                            <div className={classes.buttonRow}>
                                <Button type="submit" className={classes.button}>
                                    Chequear datos
                                </Button>
                                {
                                    Object.keys(errors).length
                                        ?
                                    Object.keys(errors).map(error => (
                                        <p className={classes.error}>{errors[error]}</p>
                                    ))
                                        : 
                                    null
                                }
                            </div>

                            {
                                inRequest 
                                    && 
                                <div className={classes.checkingData}>
                                    <p>Chequeando datos...</p>
                                    <CircularIndeterminate customMargin={'0em'}/>
                                </div>
                            }
                        </form>

                    </div>

                </div>
            </main>
    );
}