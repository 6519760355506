import React, { useState, useEffect } from "react";
// Components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { formatosXTipoDeImagen as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoFormatosPorTipoDeImagen as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { tiposDeImagenGet } from "../../services/hooli-services/TiposDeImagen/TiposDeImagenGet";
import { formatosDeImagenGet } from "../../services/hooli-services/FormatosDeImagen/FormatosDeImagenGet";
import { formatosXTipoDeImagenGet } from "../../services/hooli-services/FormatosXTipoDeImagen/FormatosXTipoDeImagenGet";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function FormatosXTipoDeImagen() {
    const [formatosXTipoDeImagen, setFormatosXTipoDeImagen] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtros, setFiltros] = useState({ tipoDeImagen: 'todos', formatoDeImagen: 'todos' })
    const [tiposDeImagen, setTiposDeImagen] = useState([]);
    const [formatosDeImagen, setFormatosDeImagen] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [action, setAction] = useState({});

    const setRow = useSetRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            retrieveData(credentials, undefined, tiposDeImagenGet, setTiposDeImagen);
            retrieveData(credentials, undefined, formatosDeImagenGet, setFormatosDeImagen);
        }
    }, [credentials]);

    async function retrieveFormatsPerImageType() {
        const params =
            filtros.tipoDeImagen === "todos" ?
                filtros.formatoDeImagen === "todos" ? {} : { "formatoDeImagenId": filtros.formatoDeImagen } :
                filtros.formatoDeImagen === "todos" ? { "tipoDeImagenId": filtros.tipoDeImagen } :
                    {
                        "tipoDeImagenId": filtros.tipoDeImagen,
                        "formatoDeImagenId": filtros.formatoDeImagen
                    };

        const payload = { ...credentials, params };
        setCargando(true);
        setRows([]);

        const data = await formatosXTipoDeImagenGet(payload);
        setFormatosXTipoDeImagen(data);
        setCargando(false);
        setRow({ status: false });
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveFormatsPerImageType()
    }, [filtros, credentials]);

    useEffect(() => {
        formatosXTipoDeImagen.map((item) => {
            const {
                feriadoSerieId,
                feriadoSerieVersion,
                feriadoSerieFecha,
                feriadoSerieEsComercial,
                feriadoSerieEsBancario
            } = item

            const serie = {
                id: feriadoSerieId,
                version: feriadoSerieVersion,
                [titles[0]]: feriadoSerieFecha,
                [titles[1]]: feriadoSerieEsComercial === 'Y' ? 'Si' : 'No',
                [titles[2]]: feriadoSerieEsBancario === 'Y' ? 'Si' : 'No',
            }

            setRows(rows => [...rows, serie])
            return item
        });
    }, [formatosXTipoDeImagen]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={retrieveFormatsPerImageType}
            />
            <Filtros
                tipos={tiposDeImagen}
                formatos={formatosDeImagen}
                filtros={filtros}
                setFiltros={setFiltros}
                cargando={cargando}
                handleClick={handleClick}
                updateList={retrieveFormatsPerImageType}
            />
            <div className="row-grid" >
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};