import axiosPost from '../axiosPost';

export const eventosDeSesionUpd = async (arg) => {
    const { id, nombre, codigo, version } = arg;

    const body = {
        "service": "EventoDeSesionUpd",
        "params": {
            'eventoSesionId': id,
            "eventoSesionNombre": nombre,
            "eventoSesionCod": codigo,
            "eventoSesionVersion": version
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}