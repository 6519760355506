import React from 'react';
// Components
import { useFormik } from 'formik';
import HeaderButton from "../../components/utils/button/HeaderButton";
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikTextInput } from '../../components/utils/inputs/FormikInputs';
// assets
import lupa from '../../assets/icons/lupa.png';
import borrar from "../../assets/icons/borrar.svg";
import reload from "../../assets/icons/actualizar.svg";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// Styles
import { FiltrosStyles } from '../Filtros.styles';
// Recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';

export default function Filtros(props) {
    const { status } = useRecoilValue(rowSelected);
    const { filtros, setFiltros, updateList, cargando } = props;

    const formik = useFormik({
        initialValues: filtros,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { nombre: { label: 'Nombre' }, cuit: { label: 'CUIT' }, documento: { label: 'Documento' } };
    const classes = FiltrosStyles();

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="nombre"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.nombre !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('nombre', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="cuit"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.cuit !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('cuit', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="documento"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.documento !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('documento', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <SubmitButton texto="Filtrar" />
            </div>
            <Buttons>
                {status ?
                    <HeaderButton
                        text="Ver información del merchant"
                        icon={lupa}
                    />
                    :
                    <HeaderButton
                        text={'Actualizar pagina'}
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </form>
    )
};