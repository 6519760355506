import axiosPost from '../axiosPost';

export const rubrosDeMerchantDel = async (arg) => {
    const { id } = arg;

    const body = {
        "service": "RubroDeMerchantDel",
        "params": { "rubroMerchantId": id }
    };
    
    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}