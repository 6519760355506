import React from 'react';
import TextField from "@material-ui/core/TextField";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import { FiltrosStyles } from '../Filtros.styles';

export default function Filtros(props) {
    const { provincias, provinciaSeleccionada, setProvinciaSeleccionada, handleClick, cargando, updateList } = props;
    const classes = FiltrosStyles();

    return (
        <div className={classes.root}>
            <div className={classes.filtros}>
                <TextField
                    label="Provincia"
                    name="provincia"
                    className={classes.input}
                    value={provinciaSeleccionada}
                    onChange={e => setProvinciaSeleccionada(e.target.value)}
                    disabled={cargando}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: false }}
                >
                    {provincias.map(p => <option key={p.provinciaId} value={p.provinciaId}>{p.provinciaNombre}</option>)}
                </TextField>
            </div>
            <Buttons texto="localidad" handleClick={handleClick} updateList={updateList} />
        </div>
    )
};