// services
import { tiposDeImagenIns } from '../../../../services/hooli-services/TiposDeImagen/TiposDeImagenIns';
import { tiposDeImagenDel } from '../../../../services/hooli-services/TiposDeImagen/TiposDeImagenDel';
import { tiposDeImagenUpd } from '../../../../services/hooli-services/TiposDeImagen/TiposDeImagenUpd';

export async function postTipoDeImagen(credentials, newTipoDeImagen, setInRequest) {
    if (Object.keys(newTipoDeImagen).length > 0) {
        const payload = { ...newTipoDeImagen, ...credentials };

        setInRequest(true);
        const response = await tiposDeImagenIns(payload);
        return response
    }
};

export async function deleteTipoDeImagen(credentials, row, setInRequest) {
    const { id } = row
    const payload = { id, ...credentials }

    setInRequest(true);
    const response = await tiposDeImagenDel(payload);
    return response
};

export async function updateTipoDeImagen(credentials, newTipoDeImagen, row, setInRequest) {
    if (Object.keys(newTipoDeImagen).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newTipoDeImagen,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await tiposDeImagenUpd(payload)
        return response
    }
};